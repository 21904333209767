import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { useSelector } from 'react-redux';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { Loader } from 'src/components/common/Loader';
import { useSiteContext, withSiteContext } from 'src/hoc/withSiteContext';
import { useIsOrganizationInMsnOnly } from 'src/modules/msn-portal/hooks/useIsOrganizationInMsnOnly';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { usePlatformRedirection } from 'src/modules/platform-app/usePlatformRedirection';
import { profileStore } from 'src/modules/profile/profile-store';
import { RequestRefundStatus } from 'src/pages/bill/pay/components/RequestRefundWizard/RequestRefundStatus';
import { RequestRefundWrapper } from 'src/pages/bill/pay/components/RequestRefundWizard/RequestRefundWrapper';
import { CompaniesRouter } from 'src/pages/companies/CompaniesRouter';
import { GetProRouter } from 'src/pages/get-pro/GetProRouter';
import { MobileAppPromotionPageContainer } from 'src/pages/mobile-app/mobileAppPromotionPageContainer';
import { msnPortalLocations, MSNPortalRouter } from 'src/pages/msn-portal';
import { companyInfoRoutes } from 'src/pages/onboarding/company-info/route';
import { DefineRelationshipRouter } from 'src/pages/onboarding/define-relationship/DefineRelationshipRouter';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import onboardingRoutes from 'src/pages/onboarding/route';
import { settingsLocations } from 'src/pages/settings/locations';
import { SettingsRouter } from 'src/pages/settings/SettingsRouter';
import { SyncQBDRouter } from 'src/pages/settings/syncQBD/SyncQBDRouter';
import { SyncSSORouter } from 'src/pages/settings/syncSSO/SyncSSORouter';
import { spendManagementLocations } from 'src/pages/spend-management/locations';
import { SpendManagementRouter } from 'src/pages/spend-management/SpendManagementRouter';
import { useHasAccessToCurrentOrg } from 'src/pages/team/team-management/hooks/useHasAccessToCurrentOrg';
import { useIsAccountingFirmSelected } from 'src/pages/team/team-management/hooks/useIsAccountingFirmSelected';
import { getCompanyInfo, getOrgId, getOwnedVendorId, getProfile } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { FeatureFlags, OrganizationCreateOrigin, RegistrationFlow, UserAuthType } from 'src/utils/consts';
import { BillWizardRouter } from '../bill/BillWizardRouter';
import { BillingRouter } from '../billing/BillingRouter';
import { ContactsRouter } from '../contacts/ContactsRouter';
import { DashboardRouter } from '../dashboard';
import CreateInvoiceRouter from '../get-paid/create/CreateInvoiceRouter';
import { globalLocations } from '../locations';
import { OnboardingAddVendorRouter } from '../onboarding/add-vendor/route';
import { defineRelationshipLocations } from '../onboarding/define-relationship/locations';
import { ReceivingMethodRouter } from '../receiving-method/ReceivingMethodRouter';
import BatchPaymentsRouter from '../regular-batch-payments/BatchPaymentsRouter';
import { isCompanyInfoDone, isCompanyLegalInfoDone } from '../settings/records';
import { InternationalWizardRouter } from '../vendor/international-delivery-method/InternationalWizardRouter';

type Props = {
  orgsRoutes: any;
};

const CompanyOnboarding = () => (
  <>
    {companyInfoRoutes.map((route) => (
      <Route key={route.path} {...route} />
    ))}
  </>
);

export const OrgsRouter = ({ orgsRoutes }: Props) => {
  const orgId = useSelector(getOrgId);
  const companyInfo = useSelector(getCompanyInfo);
  const profile = useSelector(getProfile);
  const site = useSiteContext();
  const ownedVendorId = useSelector(getOwnedVendorId);
  const { hasAccessToCurrentOrg } = useHasAccessToCurrentOrg();
  const [isMsnIncomingDashboardEnabled] = useFeature(FeatureFlags.MsnIncomingDashboardEnabled, false);
  const [isPlatformAccountantsNewFlowEnabled] = useFeature(FeatureFlags.PlatformAccountantsNewFlow, false);
  const [isInviteOwnerOfAccountingFirmClientEnabled] = useFeature(
    FeatureFlags.InviteOwnerOfAccountingFirmClient,
    false
  );
  const disallowOnNoAccess = () => hasAccessToCurrentOrg;
  const { isAccountingFirmSelected } = useIsAccountingFirmSelected();
  const isMemberOfAccountingFirm = useSelector(profileStore.selectors.isMemberOfAccountingFirm);
  const redirectToCompanies = orgId ? generatePath(globalLocations.dashboard.companies, { orgId }) : '';
  const redirectToDashboardBase = orgId ? generatePath(globalLocations.dashboard.base[0], { orgId }) : '';
  const redirectToMsnIncomingDashboard = orgId ? generatePath(msnPortalLocations.base, { orgId }) : '';
  const isMsnOnlyOrganization = useIsOrganizationInMsnOnly();
  const isCompanyInfoCompleted = isCompanyInfoDone(companyInfo) && isCompanyLegalInfoDone(companyInfo);
  const shouldRedirectToInviteOwner =
    !companyInfo.hasInvitedOwner && isCompanyInfoCompleted && isInviteOwnerOfAccountingFirmClientEnabled;
  let redirectTo = redirectToDashboardBase;

  if (isAccountingFirmSelected) {
    redirectTo = redirectToCompanies;
  } else if (isMsnOnlyOrganization) {
    redirectTo = redirectToMsnIncomingDashboard;
  }

  const getOnboardingRedirectUrl = () => {
    if (profile.registrationFlow === RegistrationFlow.SPEND_MANAGEMENT) {
      return generatePath(spendManagementLocations.onboarding, {
        orgId,
      });
    }

    if (shouldRedirectToInviteOwner) {
      analytics.track('orgs-router', 'redirect-to-invite-owner', {
        isMemberOfAccountingFirm,
        isAccountingFirmSelected,
      });

      return generatePath(defineRelationshipLocations.inviteOwner, { orgId });
    }

    return ownedVendorId
      ? generatePath(onboardingLocations.vendorCompanyInfo.name, {
          orgId,
        })
      : generatePath(site.onboardingEntryUrl, { orgId });
  };

  const shouldRedirectToOnboarding =
    !companyInfo.allowedActions.canDoPayments &&
    site.onboardingEntryUrl &&
    (shouldRedirectToInviteOwner || !isCompanyInfoCompleted);

  const shouldUsePlatformOnboarding =
    isPlatformAccountantsNewFlowEnabled &&
    shouldRedirectToOnboarding &&
    companyInfo.createOrigin === OrganizationCreateOrigin.ADD_COMPANY;

  const { isRedirecting } = usePlatformRedirection({
    isEnabled: shouldUsePlatformOnboarding || !shouldRedirectToOnboarding,
    featureFlagsConfig: { [FeatureFlags.PlatformAccountantsNewFlow]: isPlatformAccountantsNewFlowEnabled },
  });

  if (isRedirecting) {
    return <Loader color="primary" context="page" />;
  }

  return (
    <Switch>
      <SmartRoute path={onboardingLocations.defineRelationship.base} allowFor="authenticated">
        <DefineRelationshipRouter />
      </SmartRoute>
      <SmartRoute
        path={globalLocations.spendManagement.index}
        allowCallbackFn={disallowOnNoAccess}
        allowFor={UserAuthType.AUTHENTICATED}
      >
        <SpendManagementRouter />
      </SmartRoute>
      <SmartRoute path={onboardingLocations.companyInfo.index}>
        <CompanyOnboarding />
      </SmartRoute>
      <SmartRoute path={onboardingLocations.mobileApp.promotion}>
        <MobileAppPromotionPageContainer />
      </SmartRoute>
      <SmartRoute path={onboardingLocations.addVendor.index}>
        <OnboardingAddVendorRouter />
      </SmartRoute>
      <SmartRoute path={globalLocations.bill.refund}>
        <Switch>
          <RequestRefundWrapper />
        </Switch>
      </SmartRoute>
      <SmartRoute path={globalLocations.bill.successScheduledRefund}>
        <Switch>
          <RequestRefundStatus />
        </Switch>
      </SmartRoute>
      {onboardingRoutes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
      {shouldRedirectToOnboarding && !shouldUsePlatformOnboarding && (
        <SmartRoute allowFor="authenticated" component={() => <Redirect to={getOnboardingRedirectUrl()} />} />
      )}
      <SmartRoute exact allowFor={[UserAuthType.AUTHENTICATED]} path={globalLocations.orgs.index}>
        <Redirect to={redirectTo} />
      </SmartRoute>
      <SmartRoute exact allowFor={UserAuthType.AUTHENTICATED} path={globalLocations.dashboard.base}>
        <DashboardRouter />
      </SmartRoute>
      <SmartRoute
        allowFor={UserAuthType.AUTHENTICATED}
        allowCallbackFn={disallowOnNoAccess}
        path={globalLocations.bill.base}
      >
        <BillWizardRouter />
      </SmartRoute>
      <SmartRoute
        allowFor={UserAuthType.AUTHENTICATED}
        allowCallbackFn={disallowOnNoAccess}
        path={globalLocations.contacts.base}
      >
        <ContactsRouter />
      </SmartRoute>
      <SmartRoute path={globalLocations.companies.base} allowFor={UserAuthType.AUTHENTICATED}>
        <CompaniesRouter />
      </SmartRoute>
      <SmartRoute
        path={globalLocations.getPaid.create.base}
        allowCallbackFn={disallowOnNoAccess}
        allowFor={UserAuthType.AUTHENTICATED}
      >
        <CreateInvoiceRouter />
      </SmartRoute>
      <SmartRoute
        path={globalLocations.getPro.base}
        allowCallbackFn={disallowOnNoAccess}
        allowFor={UserAuthType.AUTHENTICATED}
      >
        <GetProRouter />
      </SmartRoute>
      <SmartRoute
        path={globalLocations.msnPortal.base}
        allowFor={UserAuthType.AUTHENTICATED}
        allowCallbackFn={() => disallowOnNoAccess() && isMsnIncomingDashboardEnabled}
      >
        <MSNPortalRouter />
      </SmartRoute>
      <SmartRoute
        path={globalLocations.regularBatchPayments.base}
        allowFor={UserAuthType.AUTHENTICATED}
        allowCallbackFn={disallowOnNoAccess}
      >
        <BatchPaymentsRouter />
      </SmartRoute>
      <SmartRoute
        path={globalLocations.receivingMethod.base}
        allowFor={[UserAuthType.GUEST, UserAuthType.AUTHENTICATED]}
        allowCallbackFn={disallowOnNoAccess}
      >
        <ReceivingMethodRouter />
      </SmartRoute>
      <SmartRoute
        path={globalLocations.vendor.international.base}
        allowFor={UserAuthType.AUTHENTICATED}
        allowCallbackFn={disallowOnNoAccess}
      >
        <InternationalWizardRouter />
      </SmartRoute>
      <SmartRoute path={settingsLocations.base} allowFor={UserAuthType.AUTHENTICATED}>
        <SettingsRouter />
      </SmartRoute>
      {orgsRoutes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
      <SmartRoute path={globalLocations.settings.syncQBDLocations.base} allowFor={UserAuthType.AUTHENTICATED}>
        <SyncQBDRouter />
      </SmartRoute>
      <SmartRoute path={globalLocations.settings.syncSSOLocations.base} allowFor={UserAuthType.AUTHENTICATED}>
        <SyncSSORouter />
      </SmartRoute>
      <SmartRoute path={globalLocations.billingFee.base} allowFor={UserAuthType.AUTHENTICATED}>
        <BillingRouter />
      </SmartRoute>
      <SmartRoute
        allowFor={UserAuthType.AUTHENTICATED}
        component={withSiteContext()(({ site }) => (
          <site.theme.pages.NotFoundPage />
        ))}
      />
    </Switch>
  );
};

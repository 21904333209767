import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';
import { AccountingPlatformAccountId } from '../types';

// IMPORTANT NOTE:
// since we use fetch fundingSource only once, when app is started
// please don't forget to refresh them when you delete/adde/update them

export const financialAccountsApi = {
  createPlaidLinkToken(orgId) {
    const url = `/orgs/${orgId}/funding-sources/plaid/create-link-token`;

    return postRequest(url);
  },
  addPlaidAccount(orgId: number, params) {
    const url = `/orgs/${orgId}/funding-sources/plaid/add-accounts`;

    return postRequest(url, params);
  },

  getFundingSources(orgId: number, params) {
    const url = `/orgs/${orgId}/funding-sources`;

    return fetchRequest(url, params);
  },

  /**
   * @deprecated
   */
  addCardAccount(orgId: number, params, options) {
    const url = `/orgs/${orgId}/funding-sources/card/add-accounts`;

    return postRequest(url, params, {}, options);
  },

  createCardAccount(orgId: number, params, options) {
    const url = `/orgs/${orgId}/funding-sources/card/create-account`;

    return postRequest(url, params, {}, options);
  },

  setAccountingPlatformAccountId(
    orgId: number,
    id: number,
    params: {
      intuitAccountId: AccountingPlatformAccountId;
    }
  ) {
    const url = `/orgs/${orgId}/funding-sources/${id}/intuit-account-id`;

    return putRequest(url, params);
  },

  deleteFundingSource(orgId: number, id) {
    const url = `/orgs/${orgId}/funding-sources/${id}`;

    return deleteRequest(url);
  },

  updateFundingSourceLabel(orgId: number, id, params) {
    const url = `/orgs/${orgId}/funding-sources/${id}/nickname`;

    return putRequest(url, params);
  },

  requestMicroDeposit(orgId: number, params) {
    const url = `/orgs/${orgId}/funding-sources/micro-deposit`;

    return postRequest(url, params);
  },

  verifyMicroDeposits(orgId: number, params) {
    const url = `/orgs/${orgId}/funding-sources/verify-micro-deposit`;

    return postRequest(url, params);
  },
  getVerificationStatus(orgId: number, fundingSourceId, params) {
    const url = `/orgs/${orgId}/funding-sources/${fundingSourceId}/verification`;

    return fetchRequest(url, params);
  },
  verifyMicroDepositsWithToken(params) {
    const url = '/funding-source/verify-micro-deposit';

    return postRequest(url, params);
  },
  getMicroDepositsStatusWithToken(params) {
    const url = '/funding-source/micro-deposit-verification-status';

    return fetchRequest(url, params);
  },
  /**
   * @deprecated
   */
  checkCard(orgId: number, params) {
    const url = `/orgs/${orgId}/funding-sources/card/check`;

    return postRequest(url, params, {});
  },
  validateCard(
    orgId: number,
    params: { token: string; cardBin: number | string; cardNumberFingerprint: string; cardNumberToken: string }
  ) {
    const { cardNumberFingerprint } = params;
    const url = `/orgs/${orgId}/funding-sources/card/validate`;

    return postRequest(url, params, {
      headers: {
        'cardnumber-fingerprint': cardNumberFingerprint,
      },
    });
  },
};

import { Record } from 'immutable';
import {
  BankAccountType,
  Country,
  FundingSourceOrigin,
  KybStatus,
  SettingFeeCostType,
  TaxIdType,
} from 'src/utils/consts';
import { AccountType, CompanyAllowedActions, CompanyInfoType, UserType } from 'src/utils/types';

export const AccountRecord = Record<AccountType>(
  {
    id: NaN,
    displayName: '',
    fundingType: null,
    intuitAccountId: '',
    logo: '',
    origin: FundingSourceOrigin.PLAID,
    createdAt: '',
    updatedAt: '',
    deletedAt: null,
    isVerified: false,
    nickname: null,
    bankAccount: {
      accountType: BankAccountType.CHECKING,
      accountNumber4digits: '',
      accountNumber: '',
      canVerify: true,
      isBlocked: false,
    },
    plaidAccount: null,
    cardAccount: {
      printName: '',
      cardType: '',
      card4digits: '',
      network: '',
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
    },
  },
  'AccountRecord'
);

export const addressDefaults = {
  addressLine1: '',
  addressLine2: null,
  city: '',
  state: '',
  zipCode: null,
  countryCode: Country.US,
  googlePlaceId: null,
  aptNumber: '',
  addressLat: NaN,
  addressLng: NaN,
  formattedAddress: '',
};

export const legalAddressDefaults = {
  legalAddressLine1: '',
  legalAddressLine2: '',
  legalCity: '',
  legalState: '',
  legalZipCode: '',
  legalGooglePlaceId: '',
  legalAptNumber: '',
  legalAddressLat: NaN,
  legalAddressLng: NaN,
  legalFormattedAddress: '',
  legalCountryCode: Country.US,
};

const companyInfoDefaults = {
  id: NaN,
  ...addressDefaults,
  ...legalAddressDefaults,
  companyName: '',
  legalCompanyName: '',
  taxId: '',
  phone: '',
  contactFirstName: '',
  contactLastName: '',
  yearOfIncorporation: NaN,
  accountingSoftware: '',
  businessType: '',
  intuitLastFullUpdate: new Date(),
  logoUrl: '',
  logoId: null,
  taxIdType: TaxIdType.EIN,
  kybStatus: KybStatus.UNDECIDED,
  mql: null,
  billingSetting: {
    fee: {
      credit: {
        value: 2.9,
        type: SettingFeeCostType.PERCENT,
      },
      debit: {
        value: 0.0,
        type: SettingFeeCostType.PERCENT,
      },
      ach: {
        value: 0,
        type: SettingFeeCostType.FIXED,
      },
      card: {
        value: 0,
        type: SettingFeeCostType.FIXED,
      },
      international: {
        value: 0,
        type: SettingFeeCostType.FIXED,
      },
      'ach-to-check': {
        value: 1.5,
        type: SettingFeeCostType.FIXED,
      },
    },
  },
  ownedVendorId: null,
  ownedVendorHandle: '',
  onboardingIndustryId: null,
  onboardingSubIndustryId: null,
  onboardingIntent: null,
  companyType: null,
  createOrigin: null,
  isMicroDepositEligible: null,
  hasInvitedOwner: true,
  allowedActions: {
    canDoPayments: false,
    canPayWithFastCheck: true,
  } as CompanyAllowedActions,
  totalFreeChecksPerMonth: 2,
};

export function isCompanyLegalInfoDone(companyInfo: CompanyInfoType) {
  return !!(
    companyInfo.legalCompanyName &&
    companyInfo.legalFormattedAddress &&
    companyInfo.taxId &&
    companyInfo.taxIdType &&
    companyInfo.contactFirstName &&
    companyInfo.contactLastName &&
    companyInfo.phone
  );
}

export function isCompanyInfoDone(companyInfo: CompanyInfoType) {
  return !!(companyInfo.companyName && companyInfo.formattedAddress && companyInfo.phone);
}

export function companyInfoFactory(initialValues?: Partial<CompanyInfoType>): CompanyInfoType {
  return {
    ...companyInfoDefaults,
    ...initialValues,
  };
}

export const UserRecord = Record<UserType>(
  {
    name: '',
    email: '',
    phone: '',
  },
  'UserRecord'
);

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFundingSourceEventData } from 'src/analytics/utils/fundingSource';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useRefreshFundingSources } from 'src/modules/funding-sources/hooks/useRefreshFundingSources';
import { profileStore } from 'src/modules/profile/profile-store';
import { getBill } from 'src/redux/payBillWizard/selectors';
import { getFundingSources } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { isUnverifiedAchLimitExceeded } from 'src/utils/funding-sources';
import { VERIFICATION_OPTIONS } from '../../consts';
import { FlowCustomizationsType } from '../../types';
import { SelectVerificationTypePage } from './SelectVerificationTypePage';

export type Option = {
  id: string;
  label: string;
  description: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  recommended?: boolean;
};

type Props = {
  isDeliveryMethod: boolean;
  cancelFlow: () => void;
  goBack: () => void;
  flowCustomizations?: FlowCustomizationsType;
  setSelectedVerificationType: (selectedOption: VERIFICATION_OPTIONS) => void;
};

export const SelectVerificationTypePageContainer = ({
  isDeliveryMethod,
  cancelFlow,
  goBack,
  flowCustomizations,
  setSelectedVerificationType,
}: Props) => {
  const { flowEntryPoint } = flowCustomizations || {};
  const eventPage = `add-${isDeliveryMethod ? 'delivery-method' : 'funding-source'}`;
  const userFundingSources = useSelector(getFundingSources);
  const currentUser = useSelector(profileStore.selectors.profile);
  const currentBill = useSelector(getBill);
  const [showUnverifiedAchLimitNotification, setShowUnverifiedAchLimitNotification] = useState<boolean>(false);
  const { refreshFundingSources, isFundingSourcesRefreshing } = useRefreshFundingSources();
  const eventData = isDeliveryMethod
    ? { userId: currentUser.id }
    : getFundingSourceEventData(currentUser, userFundingSources, currentBill);

  useEffect(() => {
    !isDeliveryMethod && refreshFundingSources();
  }, [isDeliveryMethod, refreshFundingSources]);

  useEffect(() => {
    analytics.page(eventPage, 'bank-verification-type-select', { flowEntryPoint });
  }, []);

  const onPlaidClick = () => {
    analytics.track(eventPage, 'add-plaid', eventData);
    setSelectedVerificationType(VERIFICATION_OPTIONS.PLAID);
  };

  const onManualClick = () => {
    if (isUnverifiedAchLimitExceeded(userFundingSources) && !isDeliveryMethod) {
      analytics.track(eventPage, 'add-manual-limit', eventData);
      setShowUnverifiedAchLimitNotification(true);
    } else {
      analytics.track(eventPage, 'add-manual', eventData);
      setSelectedVerificationType(VERIFICATION_OPTIONS.MANUALLY);
    }
  };

  const goExit = () => {
    analytics.track(eventPage, 'exit', eventData);
    cancelFlow();
  };

  const bankType = isDeliveryMethod ? 'deliveryMethods' : 'fundingSources';
  const verificationOptions: Array<Option> = [
    {
      id: 'plaid',
      label: `flows.addBankAccount.verification.${bankType}Options.plaid.label`,
      description: `flows.addBankAccount.verification.${bankType}Options.plaid.description`,
      onClick: onPlaidClick,
      recommended: isDeliveryMethod,
    },
    {
      id: 'manual',
      label: `flows.addBankAccount.verification.${bankType}Options.manual.label`,
      description: `flows.addBankAccount.verification.${bankType}Options.manual.description`,
      onClick: onManualClick,
    },
  ];

  if (isFundingSourcesRefreshing) {
    return <AreaLoader placement="wizard" />;
  }

  return (
    <SelectVerificationTypePage
      options={verificationOptions}
      goExit={goExit}
      goPrev={goBack}
      isDeliveryMethod={isDeliveryMethod}
      showUnverifiedAchLimitNotification={showUnverifiedAchLimitNotification}
    />
  );
};

import { useFeature } from '@melio/shared-web/dist/feature-flags';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { PinInput } from 'src/core/ds/input';
import { profileStore } from 'src/modules/profile/profile-store';
import { getUserPreferences } from 'src/redux/user/selectors';
import { FeatureFlags } from 'src/utils/featureFlags';
import { useLocationState } from 'src/utils/hooks';
import { UserContextType } from 'src/utils/types';
import { useLoginUser } from '../hooks/useLoginUser';
import { MfaCodeVerificationLayoutFooter } from './components/MfaCodeVerificationLayoutFooter';
import { MfaNotificationCard } from './components/MfaNotificationCard';
import { PinInputFieldWithForceSelectionRange } from './components/PinInputFieldWithForceSelectionRange';
import {
  mapMfaFailedCodeVerificationErrors,
  mapMfaFailedCodeVerificationIllustrations,
  MfaCodeVerificationState,
} from './consts';
import { useMfaCodeVerification } from './hooks/useMfaCodeVerification';

const verificationCodeLength = 6;

export const MfaCodeVerificationPage = () => {
  const { isLoading: isLoadingLogin, initUserAndNavigate } = useLoginUser();
  const userPreferences = useSelector(getUserPreferences);
  const [resetMfaCodeKey, setResetMfaCodeKey] = useState<string>('');
  const user = useSelector(profileStore.selectors.profile);
  const [joinNewOrgFlowUrl] = useLocationState<string>('joinNewOrgFlowUrl');
  const [, isSignInMfaEnabledLoading] = useFeature<boolean>(FeatureFlags.SignInMfa, false);

  const onCodeVerificationSuccess = async () => {
    await initUserAndNavigate(
      { ...user, hasValid2Factor: true, userPreferences } as UserContextType,
      'continue-success',
      joinNewOrgFlowUrl
    );
  };

  const {
    isVerifying,
    authenticator,
    isMoreThanOneAuthenticator,
    authVerificationCodeStatus,
    isLastResendAttemptDone,
    isLastVerificationAttemptDone,
    onPrev,
    verifyCode,
    onStartOver,
    handleResendCodeClick,
    handleSendToPhoneClick,
    handleSendToEmailClick,
    handleContactSupportClick,
    resetVerificationCodeStatus,
  } = useMfaCodeVerification({
    user,
    setResetMfaCodeKey,
    onSuccess: onCodeVerificationSuccess,
  });

  const handleCodeChange = (code: string) => {
    if (code.length === verificationCodeLength) {
      verifyCode(code);
    } else {
      resetVerificationCodeStatus();
    }
  };

  const isResendCodeActionDisabled =
    [MfaCodeVerificationState.DENIED, MfaCodeVerificationState.MAX_RESEND_ATTEMPTS_REACHED].includes(
      authVerificationCodeStatus
    ) || isLastResendAttemptDone;
  const isSessionExpired = authVerificationCodeStatus === MfaCodeVerificationState.EXPIRED_MFA_SESSION;
  const errorMessage = mapMfaFailedCodeVerificationErrors[authVerificationCodeStatus];
  const illustration = authenticator ? mapMfaFailedCodeVerificationIllustrations[authenticator.type] : undefined;
  const authenticatorDesc = authenticator?.description;
  const showAreaLoader = isVerifying || isLoadingLogin;

  if (isSignInMfaEnabledLoading) {
    return <AreaLoader />;
  }

  return (
    <>
      {showAreaLoader && <AreaLoader placement="melioMe" />}
      <StepLayoutPage
        title="guests.mfaVerification.title"
        subtitle="guests.mfaVerification.subtitle"
        subTitleValues={{
          authenticator: <strong>{authenticatorDesc}</strong>,
        }}
        onPrev={onPrev}
        illustration={illustration}
        footer={
          <MfaCodeVerificationLayoutFooter
            authenticator={authenticator}
            isResendCodeActionDisabled={isResendCodeActionDisabled}
            isSessionExpired={isSessionExpired}
            isMoreThanOneAuthenticator={isMoreThanOneAuthenticator}
            onPrev={onStartOver}
            handleResendCodeClick={handleResendCodeClick}
            handleSendToPhoneClick={handleSendToPhoneClick}
            handleSendToEmailClick={handleSendToEmailClick}
            handleContactSupportClick={handleContactSupportClick}
          />
        }
      >
        <MfaNotificationCard
          authVerificationCodeStatus={authVerificationCodeStatus}
          isLastVerificationAttemptDone={isLastVerificationAttemptDone}
          onPrev={onStartOver}
        />
        <PinInput
          autoFocus
          key={resetMfaCodeKey}
          errorMessage={errorMessage}
          testId="code-input-container"
          onChange={handleCodeChange}
        >
          <PinInputFieldWithForceSelectionRange />
          <PinInputFieldWithForceSelectionRange />
          <PinInputFieldWithForceSelectionRange />
          <PinInputFieldWithForceSelectionRange />
          <PinInputFieldWithForceSelectionRange />
          <PinInputFieldWithForceSelectionRange />
        </PinInput>
      </StepLayoutPage>
    </>
  );
};

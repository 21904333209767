import { featureFlags } from '@melio/shared-web';
import get from 'lodash/get';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { useApi } from 'src/hoc/useApi';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { globalLocations } from 'src/pages/locations';
import { msnPortalLocations } from 'src/pages/msn-portal';
import { PlaidLink } from 'src/pages/onboarding/funding-sources/bank/components/PlaidLink';
import { PlaidUpgradeModal } from 'src/pages/onboarding/funding-sources/bank/components/PlaidUpgradeModal/PlaidUpgradeModal';
import { loadDeliveryMethodsAction } from 'src/redux/user/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { DeliveryMethodOrigin, DeliveryType, FeatureFlags, VerifiedStatus } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';

const eventPage = 'bank-add-plaid';

type Props = {
  onNext: (deliveryMethodId: number) => void;
  onExit: (deliveryMethodId?: string) => void;
  vendorId: number;
};

export const ReceivingMethodPlaidBankAccountPage = ({ onNext, onExit, vendorId }: Props) => {
  const { navigate } = useNavigator();
  const [isPlaidOpen, setIsPlaidOpen] = useState(true);
  const params = useParams<{ deliveryMethodId?: string }>();
  const deliveryMethodId = params?.deliveryMethodId || '';
  const deliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(deliveryMethodId));
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const [isPlaidInUpgrade] = featureFlags.useFeature(FeatureFlags.IsPlaidInUpgrade, false);
  const { state: locationState } = useLocation<Record<string, any>>();
  const origin = get(locationState, 'preservedState.origin', '');

  const { onApiCall: updatePlaidAccount } = useApi({ api: deliveryMethodsApi.updatePlaidAccount });
  const { onApiCall: addPlaidAccount } = useApi({ api: deliveryMethodsApi.addPlaidAccount });

  const refreshDeliveryMethods = () =>
    new Promise((resolve, reject) => {
      dispatch(loadDeliveryMethodsAction(resolve, reject));
    });

  const onPlaidSuccess = (token, metadata) => {
    if (!metadata || metadata.error) {
      analytics.track(eventPage, 'plaid-error', { error: metadata.error });

      return;
    }

    const { accounts } = metadata;

    if (!accounts?.length) {
      analytics.track(eventPage, 'plaid-error', {
        error: 'account not selected',
      });

      return;
    }

    const selectedAccountsIds = [accounts[0].id];
    handleCreateOrUpdateDeliveryMethodPlaidAccount(selectedAccountsIds, token);
  };

  const onPlaidExit = () => {
    analytics.track(eventPage, 'plaid-exit', { isNewPlaidMDRecoveryFlow: false, origin });
    setIsPlaidOpen(false);
    onExit(deliveryMethodId);
  };

  const handleCreateOrUpdateDeliveryMethodPlaidAccount = async (selectedAccountsIds: Array<string>, token: string) => {
    const action = deliveryMethodId ? updatePlaidAccount : addPlaidAccount;

    try {
      const {
        accounts,
      }: {
        accounts: DeliveryMethodType[];
      } = await action(orgId, vendorId, {
        token,
        selectedAccountsIds,
        deliveryMethodId,
      });
      await refreshDeliveryMethods();

      analytics.track(eventPage, 'delivery-method-success');
      analytics.track('vendor-company-delivery-method', 'add-delivery-method-success', {
        type: DeliveryType.ACH,
        vendorId,
      });

      if (origin === DeliveryMethodOrigin.MSN_CLAIM_ACCOUNT) {
        analytics.trackAction('msnClaimAccountPlaid.registration-completed', {
          networkRegistrationFlow: 'salesForm',
          accountVerificationMethod: VerifiedStatus.PLAID_VERIFIED,
        });
      }

      setIsPlaidOpen(false);
      onNext(accounts?.[0]?.id ?? NaN);
    } catch (error: any) {
      setIsPlaidOpen(false);

      if (error?.code === 'DLM20') {
        navigate(
          generatePath(globalLocations.receivingMethod.ach.select, {
            orgId,
            vendorId,
            deliveryMethodId: String(deliveryMethod.id),
          }),
          false,
          {
            preservedState: {
              origin,
              shouldShowPlaidErrorNotification: true,
            },
            redirectUrl: msnPortalLocations.base,
          }
        );
      }
    }
  };

  return isPlaidInUpgrade ? (
    <PlaidUpgradeModal goExit={onExit} />
  ) : (
    <PlaidLink orgId={orgId} onSuccess={onPlaidSuccess} onExit={onPlaidExit} showDialog={isPlaidOpen} />
  );
};

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MICardForm } from 'src/components/common/MICard';
import { MIMoney } from 'src/components/common/MIMoney';
import { InvoiceType } from 'src/modules/invoices/types';
import { useFullPaymentRequestLink } from 'src/pages/get-paid/hooks/useFullPaymentRequestLink';
import { getOwnedVendorHandle } from 'src/redux/user/selectors';
import { MiMoneyFlavor } from 'src/utils/consts';
import { removeProtocol } from 'src/utils/string-utils';

type Props = {
  invoice: InvoiceType;
};

const BatchInvoiceCard = ({ invoice }: Props) => {
  const { customer, totalAmount } = invoice;
  const flavor = MiMoneyFlavor.DEFAULT;
  const vendorHandle = useSelector(getOwnedVendorHandle);
  const fullPaymentRequestLink = useFullPaymentRequestLink({
    ownedVendorHandle: vendorHandle,
    paymentRequestLink: invoice.link || '',
  });

  return (
    <MICardForm>
      <>
        <InvoiceInfoWrraper>
          <CustomerName>{customer?.contactName}</CustomerName>
          <MIMoney amount={totalAmount} flavor={flavor} />
        </InvoiceInfoWrraper>
        <MICopyReference href={fullPaymentRequestLink} target="_open">
          {removeProtocol(fullPaymentRequestLink)}
        </MICopyReference>
      </>
    </MICardForm>
  );
};

export default BatchInvoiceCard;

const InvoiceInfoWrraper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomerName = styled.div`
  font-size: ${(props) => props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;

const MICopyReference = styled.a`
  display: block;
  color: ${(props) => props.theme.text.color.highlight};
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 1rem 0 0;
  ${(props) => props.theme.text.fontType.medium};
`;

import { format } from 'date-fns';
import { useMemo } from 'react';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';
import { useForm } from 'src/ui/form';
import { DateFormats } from 'src/utils/date-fns';

type Props = {
  noteValue: string;
  noteLabel: string;
  invoiceNumber: string;
  dueDate?: Date;
};

export const PaymentRequestDetails = (props: Props) => {
  const { invoiceNumber, noteValue, noteLabel, dueDate } = props;
  const details = {
    invoiceNumber,
    note: noteValue,
    dueDate: dueDate && format(new Date(dueDate), DateFormats.monthShortWithLongDateAndYear),
  };
  const detailsMV = useMemo(() => ({ ...details }), [props]);
  const [data] = useForm(detailsMV, { submit: async (data) => data });

  return (
    <Box id="payment-request-details">
      <Flex mb={4}>
        <TextField
          id="invoiceNumber"
          label="guests.paymentRequest.invoiceNumber"
          model={data.invoiceNumber}
          isViewOnly
          isRequired
          size="sm"
        />
        <TextField
          id="dueDate"
          label="guests.paymentRequest.dueDate"
          model={data.dueDate}
          isViewOnly
          isRequired
          size="sm"
        />
      </Flex>
      <Box id="payment-request-note">
        <TextField
          id="note"
          label={noteLabel}
          model={data.note}
          placeholder="guests.paymentRequest.noteEmpty"
          isViewOnly
          size="sm"
          wrapText
        />
      </Box>
    </Box>
  );
};

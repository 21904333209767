import { featureFlags } from '@melio/shared-web';
import { useEffect, useMemo, useState } from 'react';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/hooks';
import { organizationsApi } from 'src/modules/organizations/api';
import { PAGE_EVENT } from 'src/pages/get-pro/consts';
import { analytics } from 'src/services/analytics';
import { FeatureFlags } from 'src/utils/featureFlags';

type ShowVerifyMessageResponseType = {
  title: string;
  description: string;
  action: string;
  onClick: () => void;
  onClose: () => void;
  testId: string;
  shouldShowMtlOrganizationMigrationMessage?: boolean;
};

export const useShowMtlOrganizationMigrationMessage = (): ShowVerifyMessageResponseType => {
  const orgId = useOrgId();
  const [openMessage, setOpenMessage] = useState<boolean>(true);
  const [isGetPaidMtlCheckEnabled] = featureFlags.useFeature(FeatureFlags.IsGetPaidMtlCheckEnabled, false);
  const { loading, error, onApiCall: fetchComplianceLimitations, result: complianceLimitationsResponse } = useApi({
    api: organizationsApi.complianceLimitations,
  });
  const navigateToCompleteComplianceDetails = () => {
    window.location.href = '/complete-details';
  };

  const shouldShowMtlOrganizationMigrationMessage = useMemo(
    () =>
      Boolean(
        openMessage &&
          isGetPaidMtlCheckEnabled &&
          !loading &&
          !error &&
          !complianceLimitationsResponse?.isCompliance &&
          complianceLimitationsResponse?.isGracePeriodStarted
      ),
    [openMessage, isGetPaidMtlCheckEnabled, loading, error, complianceLimitationsResponse]
  );

  useEffect(() => {
    let isMounted = true;
    const fetchAsync = async () => {
      try {
        const results = await fetchComplianceLimitations(orgId);

        if (isMounted && !error && isGetPaidMtlCheckEnabled && !results.isCompliance && results.isGracePeriodEnded) {
          analytics.track(PAGE_EVENT, 'mtl-complete-details-auto-redirect', {
            orgId,
          });
          navigateToCompleteComplianceDetails();
        }
      } catch (error: any) {
        analytics.track(PAGE_EVENT, 'mtl-complete-details-fetch-error', {
          orgId,
          error,
        });
        // eslint-disable-next-line no-console
        console.error('complete-details-fetch-error', error);
      }
    };

    fetchAsync();

    return () => {
      isMounted = false;
    };
  }, [fetchComplianceLimitations, orgId, error, isGetPaidMtlCheckEnabled]);

  useEffect(() => {
    if (shouldShowMtlOrganizationMigrationMessage) {
      analytics.track(PAGE_EVENT, 'mtl-complete-details-show', {
        orgId,
      });
    }
  }, [shouldShowMtlOrganizationMigrationMessage, orgId]);

  const onClick = () => {
    analytics.trackAction('mtl-click-complete-details');
    navigateToCompleteComplianceDetails();
  };

  const onClose = () => {
    analytics.trackAction('mtl-close-complete-details');
    setOpenMessage(false);
  };

  return {
    onClick,
    onClose,
    action: 'getPro.message.mtlOrganizationMigration.action',
    title: 'getPro.message.mtlOrganizationMigration.title',
    shouldShowMtlOrganizationMigrationMessage,
    description: 'getPro.message.mtlOrganizationMigration.description',
    testId: 'mtl-migration-message',
  };
};

import { useEffect, useState } from 'react';
import { AreaLoader } from 'src/components/common/AreaLoader';
import config from 'src/config';
import { analytics } from 'src/services/analytics';
import { capture } from 'src/utils/error-tracking';
import { PlaidConfigProps, usePlaid } from '../hooks/usePlaid';

type PlaidLinkProps = PlaidConfigProps & { showDialog?: boolean; isLoading?: boolean };

export const PlaidLink = ({ showDialog = false, isLoading = false, orgId, ...rest }: PlaidLinkProps) => {
  const [loading, setLoading] = useState(isLoading);

  const { ready, open, exit, error } = usePlaid({
    ...rest,
    orgId,
  });

  useEffect(() => {
    if (ready) {
      setLoading(false);

      if (showDialog) {
        // Plaid dialog will open when the token will be supplied successfully
        open();
      } else {
        exit();
      }
    } else if (showDialog) {
      setLoading(true);
    }
  }, [ready, showDialog, exit, open]);

  useEffect(() => {
    if (error?.message || error?.error) {
      analytics.track('add-funding-source', `plaid-${config.services.plaid.env}-link-dialog-error`);
      capture(new Error('Plaid Link error: '), {
        orgId,
        ...(error?.message ? { errorMessage: error.message } : {}),
        ...(error?.error ? { error: JSON.stringify(error.error) } : {}),
      });
      exit?.();
    }
  }, [error, exit, orgId]);

  return loading ? <AreaLoader /> : <></>;
};

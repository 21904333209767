import findLastKey from 'lodash/findLastKey';
import { FeatureFlags } from 'src/utils/featureFlags';

const platformPathMapping = {
  '/orgs/*/bills': {
    pattern: /^\/orgs\/\d+\/bills.*$/,
    platformPath: 'pay/inbox',
  },
  '/orgs/*/bills?campaign=financing': {
    pattern: /^\/orgs\/\d+\/bills\?.*campaign=financing.*$/,
    platformPath: 'financing/application?campaign=financing',
  },
  '/orgs/*/bills?status=unpaid': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=unpaid.*$/,
    platformPath: 'pay/inbox',
  },
  '/orgs/*/bills?status=scheduled': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=scheduled.*$/,
    platformPath: 'pay/scheduled',
  },
  '/orgs/*/bills?status=paid': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=paid.*$/,
    platformPath: 'pay/paid',
  },
  '/orgs/*/bills?status=unpaid&id=:billId': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=unpaid.*&id=(?<billId>\d+)?.*$/,
    platformPath: 'pay/inbox/bill_:billId',
  },
  '/orgs/*/bills?status=unpaid&id=scanned-:scannedId': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=unpaid.*&id=scanned-(?<scannedId>\d+)?.*$/,
    platformPath: 'pay/inbox/scn_:scannedId',
  },
  '/orgs/*/bills?status=unpaid&id=request-:paymentRequestId': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=unpaid.*&id=request-(?<paymentRequestId>\d+)?.*$/,
    platformPath: 'pay/inbox/pymntreq_:paymentRequestId',
  },
  '/orgs/*/bills?status=unpaid&id=payment-:billId-:paymentId': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=unpaid.*&id=payment-(?<billId>\d+)?-(?<paymentId>\d+)?.*$/,
    platformPath: 'pay/inbox/pymnt_:paymentId',
  },
  '/orgs/*/bills?status=scheduled&id=payment-:billId-:paymentId': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=scheduled.*&id=payment-(?<billId>\d+)?-(?<paymentId>\d+)?.*$/,
    platformPath: 'pay/scheduled/pymnt_:paymentId',
  },
  '/orgs/*/bills?status=paid&id=payment-:billId-:paymentId': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=paid.*&id=payment-(?<billId>\d+)?-(?<paymentId>\d+)?.*$/,
    platformPath: 'pay/paid/pymnt_:paymentId',
  },
  '/orgs/*/contacts/vendors': {
    pattern: /^\/orgs\/\d+\/contacts\/vendors.*$/,
    platformPath: 'vendors',
  },
  '/orgs/*/contacts/vendors?id=:vendorId': {
    pattern: /^\/orgs\/\d+\/contacts\/vendors\?id=(?<vendorId>\d+)?.*$/,
    platformPath: 'vendors/vendor_:vendorId',
  },
  '/orgs/*/settings': {
    pattern: /^\/orgs\/\d+\/settings$/,
    platformPath: 'settings',
  },
  '/orgs/*/settings/account': {
    pattern: /^\/orgs\/\d+\/settings\/account$/,
    platformPath: 'settings/profile',
  },
  '/orgs/*/settings/users': {
    pattern: /^\/orgs\/\d+\/settings\/users$/,
    platformPath: 'settings/collaborators',
  },
  '/orgs/*/settings/company': {
    pattern: /^\/orgs\/\d+\/settings\/company$/,
    platformPath: 'settings/company',
  },
  '/orgs/*/settings/email-notifications': {
    pattern: /^\/orgs\/\d+\/settings\/email-notifications$/,
    platformPath: 'settings/notification-preferences',
  },
  '/orgs/*/settings/accounting-software': {
    pattern: /^\/orgs\/[^\\/]+\/settings\/accounting-software$/,
    platformPath: 'settings/accounting-software',
  },
  '/orgs/*/settings/payment-methods': {
    pattern: /^\/orgs\/\d+\/settings\/payment-methods$/,
    platformPath: 'settings/payment-methods',
  },
  '/orgs/*/settings/approval-workflows': {
    pattern: /^\/orgs\/\d+\/settings\/approval-workflows$/,
    platformPath: 'settings/approval-workflows',
  },
  '/orgs/*/settings/billing-items': {
    pattern: /^\/orgs\/\d+\/settings\/billing-items.*$/,
    platformPath: 'settings/billing',
  },
  '/orgs/*/settings/support': {
    pattern: /^\/orgs\/\d+\/settings\/support$/,
    platformPath: 'settings/support',
  },
  '/orgs/*/companies': {
    pattern: /^\/orgs\/\d+\/companies$/,
    platformPath: 'accounts',
    accountingFirmPlatformPath: '/accountants/dashboard',
    accountingFirmSpecificFlag: FeatureFlags.PlatformAccountantsNewFlow,
  },
  '/orgs/*/batch-payments': {
    pattern: /^\/orgs\/\d+\/batch-payments.*$/,
    platformPath: 'pay/inbox',
  },
  '/orgs/*/just-pay': {
    pattern: /^\/orgs\/\d+\/just-pay$/,
    platformPath: 'schedule-payment/new?origin=onboarding',
  },
  'orgs/*/complete-details': {
    pattern: /^\/orgs\/\d+\/complete-details$/,
    platformPath: 'complete-details?origin=get-paid-notification',
  },
  '/orgs/payment-request/:paymentRequestId/pay': {
    pattern: /^\/orgs\/payment-request\/(?<paymentRequestId>.+)\/pay$/,
    platformPath: 'payment-request/:paymentRequestId/pay',
  },
  '/orgs/*/companies/new/details': {
    pattern: /^\/orgs\/\d+\/companies\/new\/details$/,
    platformPath: '/accountants/client/creation-options',
    accountingFirmSpecificFlag: FeatureFlags.PlatformAccountantsNewFlow,
    isOnlyForAccountingFirm: true,
  },
};

export const getPlatformPath = (
  route: string,
  isAccountingFirm = false,
  featureFlagsConfig?: { [key: string]: boolean }
): null | string => {
  let newPath = null;
  const mostSpecificPath = findLastKey(platformPathMapping, ({ pattern }) => !!route.match(pattern));

  if (mostSpecificPath) {
    const {
      pattern,
      platformPath,
      accountingFirmPlatformPath,
      accountingFirmSpecificFlag,
      isOnlyForAccountingFirm,
    } = platformPathMapping[mostSpecificPath];
    const match = route.match(pattern);
    const customFeatureFlagValue = accountingFirmSpecificFlag && featureFlagsConfig?.[accountingFirmSpecificFlag];

    if (
      (isAccountingFirm && accountingFirmSpecificFlag && !customFeatureFlagValue) ||
      (isOnlyForAccountingFirm && !isAccountingFirm)
    ) {
      return null;
    }

    if (match) {
      const pathForCurrentUser =
        isAccountingFirm && accountingFirmPlatformPath ? accountingFirmPlatformPath : platformPath;
      newPath = pathForCurrentUser;

      if (match.groups) {
        newPath = Object.entries(match.groups).reduce(
          (acc, [groupName, groupValue]) => acc.replace(`:${groupName}`, groupValue),
          pathForCurrentUser
        );
      }
    }
  }

  return newPath;
};

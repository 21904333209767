import { useState } from 'react';
import { IconNames } from 'src/core/ds/icon';
import { IconInput } from 'src/core/ds/iconInput';
import { Input, InputGroup, InputProps, InputRightElement, Patterns, Types } from 'src/core/ds/input';

export type Props = InputProps & {
  iconTestId?: string | null;
  shouldShowValue?: boolean;
  inputMode?: Types.InputModeType;
  pattern?: Patterns.InputPatterns;
};

export const PasswordInput = ({
  iconTestId,
  isViewOnly = false,
  isDisabled = false,
  isReadOnly = false,
  shouldShowValue = false,
  inputMode,
  size = 'md',
  onFocus,
  onBlur,
  ...rest
}: Props) => {
  const [showValue, setShowValue] = useState(shouldShowValue);
  const [isFocus, setIsFocus] = useState(false);
  const shouldHideIcon = isReadOnly || isViewOnly;
  const inputType = showValue && !isViewOnly ? 'text' : 'password';

  const handleClick = () => !isDisabled && setShowValue(!showValue);

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(true);
    onFocus && onFocus(event);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(false);
    onBlur && onBlur(event);
  };

  return (
    <InputGroup>
      <Input
        type={inputType}
        isViewOnly={isViewOnly}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        size={size}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...rest}
      />
      {!shouldHideIcon && (
        <InputRightElement
          data-testid="input-password-show"
          onClick={handleClick}
          cursor={isDisabled ? 'default' : 'pointer'}
          h="full"
        >
          <IconInput
            name={showValue ? IconNames.eyeHide : IconNames.eyeShow}
            size={size}
            isDisabled={isDisabled}
            isFocus={isFocus}
            testId={iconTestId ? `${iconTestId}-show` : null}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

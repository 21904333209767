export const accountingSoftwareCardStyle = {
  bg: 'white',
  w: '21.7rem',
  h: '5rem',
  pt: 5,
  pb: 5,
  justifyContent: 'center',
  borderRadius: 'lg',
  shadow: '500',
  cursor: 'pointer',
  border: '2px',
  borderColor: 'transparent',
  '&:hover': {
    shadow: '600',
  },
  '&[data-selected=true]': {
    borderColor: 'primary.500',
  },
};

export const accountingSoftwareCardContainer = {
  display: 'flex',
  justifyContent: { base: 'center', md: 'flex-start' },

  ':nth-child(odd)': {
    justifyContent: { base: 'center', md: 'flex-end' },
  },
};

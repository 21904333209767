import isEmpty from 'lodash/isEmpty';
import negate from 'lodash/negate';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { useMemo, useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { PasswordField, TextField } from 'src/core/ds/form/fields';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { VStack } from 'src/core/ds/stack';
import Tooltip from 'src/core/ds/tooltip';
import { useApi } from 'src/hoc/useApi';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { msnPortalApi } from 'src/modules/msn-portal/api';
import { useBusinessTypeOptions } from 'src/pages/vendor/msn-candidates/hooks/useBusinessTypeOptions';
import { useTaxIdTypeOptions } from 'src/pages/vendor/msn-candidates/hooks/useTaxIdTypeOptions';
import { BankAccountDetails, SUVCCandidateDetails } from 'src/pages/vendor/msn-candidates/types';
import { virtualCardCandidatesLocations } from 'src/pages/vendor/virtual-card/candidates/locations';
import { virtualCardCandidateEventPage } from 'src/pages/vendor/virtual-card/candidates/VirtualCardCandidatesRouter';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { useForm } from 'src/ui/form';
import { FormRow } from 'src/ui/form/FormElements';
import { WizardSelectField } from 'src/ui/form/WizardSelectField';
import { convertToServerAddress } from 'src/utils/address';
import { AddressFormControlMode, NotificationVariant } from 'src/utils/consts';
import { AddressType, GoogleCombinedAddressType, MsnBusinessTypeEnum } from 'src/utils/types';
import { formSectionTitleStyle, limitDisclaimerStyle, titleLinkStyle } from './styles';
import { validateCandidatesDetails } from './utils';

export type CandidatesDetailsModelType = {
  contactFirstName: string;
  contactLastName: string;
  companyName: string;
  address: string;
  email: string;
  emailConfirmation: string;
  phone: string;
  phoneExt?: string;
  googlePlaceId: string;
  addressLine1: string;
  addressLine2?: string;
  aptNumber?: string;
  state: string;
  city: string;
  zipCode: string;
  countryCode: string;
  msnBusinessType: string;
  routingNumber?: string;
  accountNumber?: string;
  taxId?: string;
  taxIdType?: string;
};

const CandidatesDetailsForm = ({ capLimitAmount }) => {
  const { token, id } = useRouteMatch<{ token: string; id: string }>().params;
  const history = useHistory();
  const site = useSiteContext();
  const [address, setAddress] = useState<GoogleCombinedAddressType | null>(null);
  const { businessTypeOptions } = useBusinessTypeOptions();
  const hasCapLimit = !!capLimitAmount;
  const model = useMemo<CandidatesDetailsModelType>(
    () => ({
      contactFirstName: '',
      contactLastName: '',
      companyName: '',
      address: '',
      email: '',
      emailConfirmation: '',
      phone: '',
      phoneExt: '',
      googlePlaceId: '',
      addressLine1: '',
      addressLine2: '',
      aptNumber: '',
      state: '',
      city: '',
      zipCode: '',
      countryCode: 'US',
      msnBusinessType: '',
      routingNumber: '',
      accountNumber: '',
      taxId: '',
      taxIdType: '',
    }),
    []
  );

  const { taxIdTypeOptions } = useTaxIdTypeOptions();

  const { onApiCall: submitCandidateForm, loading: isLoading } = useApi<
    [string, SUVCCandidateDetails],
    Record<string, any>
  >({
    api: msnPortalApi.submitCandidateForm,
  });

  const [candidatesInfoMV, { submit }, modelValidationErrors] = useForm<CandidatesDetailsModelType>(model, {
    submit: async (value) => {
      const bankAccountParams = pickBy(pick(value, ['routingNumber', 'accountNumber']), negate(isEmpty));

      const params: SUVCCandidateDetails = {
        firstName: value.contactFirstName,
        lastName: value.contactLastName,
        ...pick(value, ['companyName', 'email', 'phone', 'phoneExt', 'taxId', 'taxIdType']),
        address: {
          ...pick(value, ['googlePlaceId', 'addressLine1', 'addressLine2', 'aptNumber', 'state', 'zipCode', 'city']),
          formattedAddress: value.address,
          countryCode: 'US',
        },
        ...(!isEmpty(bankAccountParams) && { bankAccount: bankAccountParams as BankAccountDetails }),
        msnBusinessType: value.msnBusinessType,
      };

      if (validateCandidatesDetails(value, hasCapLimit)) {
        try {
          await submitCandidateForm(token, params);
          analytics.track(virtualCardCandidateEventPage, 'submit-success', { candidateId: id });
          history.push(generatePath(virtualCardCandidatesLocations.success, { token }));
        } catch (err: any) {
          analytics.track(virtualCardCandidateEventPage, 'submit-error', {
            candidateId: id,
            error: err,
          });
          pushNotification({
            type: NotificationVariant.ERROR,
            msg: 'server.ERR',
          });
        }
      }
    },
  });

  const isOptionalTextHide =
    hasCapLimit ||
    candidatesInfoMV.msnBusinessType.value === MsnBusinessTypeEnum.PART_OF_A_LARGER_ORGANIZATION ||
    candidatesInfoMV.msnBusinessType.value === MsnBusinessTypeEnum.NOT_SURE;

  const updateAddressModel = (address: AddressType) => {
    const {
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      countryCode,
      aptNumber,
      googlePlaceId,
      formattedAddress,
    } = address;
    candidatesInfoMV.googlePlaceId.onChange({ value: googlePlaceId || '' });
    candidatesInfoMV.address.onChange({ value: formattedAddress || '' });
    candidatesInfoMV.addressLine1.onChange({ value: addressLine1 || '' });
    candidatesInfoMV.addressLine2.onChange({ value: addressLine2 || '' });
    candidatesInfoMV.city.onChange({ value: city || '' });
    candidatesInfoMV.state.onChange({ value: state || '' });
    candidatesInfoMV.zipCode.onChange({ value: zipCode || '' });
    candidatesInfoMV.countryCode.onChange({ value: countryCode || '' });
    candidatesInfoMV.aptNumber.onChange({ value: aptNumber || '' });
  };

  const onChangeAddress = (address: GoogleCombinedAddressType) => {
    if (address && address?.addressComponents?.length > 0) {
      const convertedAddress = convertToServerAddress(address);
      const { googlePlaceId, formattedAddress } = convertedAddress;

      if (googlePlaceId && formattedAddress) {
        updateAddressModel(convertedAddress);
      }

      setAddress(address);
    } else {
      updateAddressModel({});
      setAddress(null);
    }
  };

  const onWhatIsSingleUseCardClick = () => {
    analytics.track('virtual-card-candidates', 'what-is-single-card-use-click');
    window.open('https://melio.zendesk.com/hc/en-us/articles/4446886964370-What-is-a-single-use-card-');
  };

  const onEmailConfirmationFieldPaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
  };

  return (
    <Box sx={{ px: { base: 4, md: 10 }, py: { base: 8 } }}>
      <VStack spacing={6}>
        <VStack sx={{ mb: 4, alignItems: 'start' }} spacing={2}>
          <Box textStyle="body1Semi">
            <MIFormattedText label="vendors.virtualCard.candidates.header.title" />
          </Box>
          <Box textStyle="body2">
            <MIFormattedText
              label="vendors.virtualCard.candidates.form.title"
              values={{
                virtualCardLink: (...chunks) => (
                  <Box as="a" sx={titleLinkStyle} onClick={onWhatIsSingleUseCardClick}>
                    {chunks}
                  </Box>
                ),
              }}
            />
          </Box>
        </VStack>
        <FormRow>
          <TextField
            model={candidatesInfoMV.email}
            isRequired
            helperText="vendors.virtualCard.candidates.form.emailHint"
            label="vendors.virtualCard.candidates.form.emailLabel"
          />
        </FormRow>
        <FormRow>
          <TextField
            model={candidatesInfoMV.emailConfirmation}
            label="vendors.candidates.form.emailConfirmationLabel"
            isRequired
            onPaste={onEmailConfirmationFieldPaste}
            autoComplete="off"
          />
        </FormRow>
        <FormRow>
          <TextField
            model={candidatesInfoMV.companyName}
            label="vendors.virtualCard.candidates.form.dbaNameLabel"
            isRequired
          />
        </FormRow>
        <FormRow>
          <MIAddressAutocomplete
            id="address"
            addressLabel="vendors.virtualCard.candidates.form.businessLegalAddressLabel"
            addressPlaceholder=""
            address={address}
            onChange={onChangeAddress}
            errorMessage={modelValidationErrors?.googlePlaceId}
            required
            hideSuite
            mode={AddressFormControlMode.INLINE}
            marginBottomOverride="0"
          />
        </FormRow>
        <FormRow>
          <WizardSelectField
            required
            isSearchable={false}
            model={candidatesInfoMV.msnBusinessType}
            placeholder="vendors.virtualCard.candidates.form.fcbStatusPlaceholder"
            label="vendors.virtualCard.candidates.form.fcbStatusLabel"
            hint="vendors.virtualCard.candidates.form.fcbStatusHint"
            options={businessTypeOptions}
          />
        </FormRow>
        <Box sx={formSectionTitleStyle}>
          <MIFormattedText label="vendors.virtualCard.candidates.form.bankDetailsSectionTitle" />
          <Tooltip
            label={<MIFormattedText label="vendors.virtualCard.candidates.form.bankDetailsSectionTooltip" />}
            placement="top"
          >
            <Icon ml="1" name={IconNames.infoCircle} size={IconSize.s} />
          </Tooltip>
        </Box>
        <FormRow>
          <TextField
            model={candidatesInfoMV.routingNumber}
            isRequired={isOptionalTextHide}
            label="vendors.virtualCard.candidates.form.routingNumberLabel"
            optionalLabelText="input.recommended"
          />
          <TextField
            model={candidatesInfoMV.accountNumber}
            isRequired={isOptionalTextHide}
            label="vendors.virtualCard.candidates.form.accountNumberLabel"
            optionalLabelText="input.recommended"
            helperText={isOptionalTextHide ? 'vendors.virtualCard.candidates.form.bankAccountHint' : undefined}
          />
        </FormRow>
        <FormRow>
          <WizardSelectField
            required
            isSearchable={false}
            model={candidatesInfoMV.taxIdType}
            placeholder="vendors.virtualCard.candidates.form.taxIdType.placeholder"
            label="vendors.virtualCard.candidates.form.taxIdType.label"
            options={taxIdTypeOptions}
            testId="input-taxIdType"
          />
          <PasswordField
            model={candidatesInfoMV.taxId}
            label="vendors.virtualCard.candidates.form.taxId.label"
            optionalLabelText="input.recommended"
            testId="input-taxId"
            sx={{
              '.chakra-form__error-message': {
                mt: 3,
              },
            }}
          />
        </FormRow>
        <Box sx={formSectionTitleStyle}>
          <MIFormattedText label="vendors.virtualCard.candidates.form.accountsReceivebleContactSection" />
          <Tooltip
            label={
              <MIFormattedText label="vendors.virtualCard.candidates.form.accountsReceivebleContactSectionTooltip" />
            }
            placement="top"
          >
            <Icon ml="1" name={IconNames.infoCircle} size={IconSize.s} />
          </Tooltip>
        </Box>
        <FormRow>
          <TextField
            model={candidatesInfoMV.contactFirstName}
            label="vendors.virtualCard.candidates.form.firstNameLabel"
            isRequired
          />
          <TextField
            model={candidatesInfoMV.contactLastName}
            label="vendors.virtualCard.candidates.form.lastNameLabel"
            isRequired
          />
        </FormRow>
        <FormRow>
          <TextField isRequired model={candidatesInfoMV.phone} label="vendors.virtualCard.candidates.form.phoneLabel" />
          <TextField model={candidatesInfoMV.phoneExt} label="vendors.virtualCard.candidates.form.phoneExtLabel" />
        </FormRow>
        {hasCapLimit && (
          <Box sx={limitDisclaimerStyle}>
            <Icon name={IconNames.infoCircle} />
            <MIFormattedText
              label="vendors.virtualCard.candidates.form.limitDisclaimer"
              values={{ amount: capLimitAmount }}
            />
          </Box>
        )}
        <VStack spacing={4}>
          <Button
            w="full"
            isLoading={isLoading}
            size={ButtonSizes.lg}
            onClick={submit}
            label="vendors.virtualCard.candidates.form.action"
          />
          {!isLoading && !isEmpty(modelValidationErrors) && (
            <Box textStyle="body4" color="red.500">
              <MIFormattedText label="vendors.candidates.form.validation.form" />
            </Box>
          )}
          <Box textStyle="caption1" color="grey.700" mt={4}>
            <MIFormattedText
              label="vendors.virtualCard.candidates.form.disclaimer"
              values={{
                termsOfServiceLink: (...chunks) => (
                  <Box as="a" textStyle="link4" target="_blank" href={site.config.agreementLinks.userAgreement}>
                    {chunks}
                  </Box>
                ),
                privacyPolicyLink: (...chunks) => (
                  <Box as="a" textStyle="link4" target="_blank" href={site.config.agreementLinks.privacyPolicy}>
                    {chunks}
                  </Box>
                ),
              }}
            />
          </Box>
        </VStack>
      </VStack>
    </Box>
  );
};

export { CandidatesDetailsForm };

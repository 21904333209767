import { featureFlags } from '@melio/shared-web';
import noop from 'lodash/noop';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NotificationVariant } from 'src/core/ds/toast';
import { LinkAccountingPlatform } from 'src/flows/add-bank-account-flow/steps/link-accounting-platform/LinkAccountingPlatform';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks';
import { useWizard, UseWizardArgs } from 'src/hooks/useWizard/useWizard';
import { getFundingSources } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications';
import { FeatureFlags } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { isUnverifiedAchLimitExceeded } from 'src/utils/funding-sources';
import { STEPS, VERIFICATION_OPTIONS } from './consts';
import { AddManuallyBankAccountPage } from './steps/manual-account/AddManuallyBankAccountPage';
import { PendingBankAccountsVerificationPage } from './steps/pending-verification/PendingBankAccountsVerificationPage';
import { AddPlaidBankAccountPage } from './steps/plaid-account/AddPlaidBankAccountPage';
import { SelectVerificationTypePageContainer } from './steps/select/SelectVerificationTypePageContainer';
import { AddPlaidBankAccountSuccessPage } from './steps/success/AddPlaidBankAccountSuccessPage';
import { FlowCustomizationsType, Steps } from './types';

type Props = {
  vendorId?: string;
  isDeliveryMethod?: boolean;
  flowCustomizations: FlowCustomizationsType;
  saveAndContinuePath?: string | (() => void);
};

const locationsMap: UseWizardArgs<Steps>['locationsMap'] = {
  select: 'select',
  plaid: 'plaid',
  manually: 'manually',
  'link-account': 'link-account',
  pending: 'pending',
  success: 'success',
};

export const navigationMap = {
  select: (selectedOption: VERIFICATION_OPTIONS) => (selectedOption as unknown) as STEPS,
  plaid: (isSuccess: boolean) => (isSuccess ? STEPS.SUCCESS : STEPS.MANUALLY),
  manually: (isConnectedToAccountingPlatform: boolean, isDeliveryMethod: boolean) =>
    isConnectedToAccountingPlatform && !isDeliveryMethod ? STEPS.LINK_ACCOUNT : STEPS.PENDING,
  'link-account': (isManual: boolean) => (isManual ? STEPS.PENDING : STEPS.SUCCESS),
  pending: noop,
  success: noop,
};

export const AddBankAccountFlow = ({
  vendorId,
  isDeliveryMethod = false,
  flowCustomizations,
  saveAndContinuePath,
}: Props) => {
  const history = useHistory();
  const [isManuallyFromPlaid, setIsManuallyFromPlaid] = useState<boolean>(false);
  const [isManuallyCreated, setIsManuallyCreated] = useState<boolean>(false);
  const [fundingSourceId, setFundingSourceId] = useState<string | undefined>(undefined);
  const [skipSuccessStep, setSkipSuccessStep] = useState<boolean>(false);
  const [skipBankSuccessStep] = featureFlags.useFeature(FeatureFlags.GuestPayorFlowRemoveScreensPhase1, false);
  const userFundingSources = useSelector(getFundingSources);
  const shouldGoBack = isUnverifiedAchLimitExceeded(userFundingSources) && !isDeliveryMethod;
  const { isConnected: isConnectedAccountingPlatform } = usePayablesConnectedAccountingPlatform();
  const { cancelFlow, goNextMap, goBack, completeFlow, getExtNavReturnUrl, currentStep } = useWizard<
    Steps,
    typeof navigationMap
  >({
    firstStep: 'select',
    flowName: 'add-bank-account-flow',
    locationsMap,
    navigationMap,
    cancelUrlFallback: '/',
  });

  const saveAndContinue = useCallback(() => {
    if (saveAndContinuePath) {
      completeFlow(saveAndContinuePath);
    } else {
      cancelFlow();
    }
  }, [cancelFlow, completeFlow, saveAndContinuePath]);

  useEffect(() => {
    if (skipSuccessStep) {
      saveAndContinue();
    }
  }, [saveAndContinue, skipSuccessStep]);

  useEffect(() => {
    if (isDeliveryMethod && !vendorId) {
      capture(new Error('Missing vendorId in adding bank account delivery method'));
      pushNotification({
        type: NotificationVariant.error,
        msg: 'flows.addBankAccount.verification.vendorIdMissingExceptionMessage',
      });
      cancelFlow();
    }
  }, [isDeliveryMethod, vendorId, cancelFlow]);
  const enrichedFlowCustomizations = useMemo(
    () => ({
      ...flowCustomizations,
      onFSBankAccountAdded: (fundingSourceId: string) => {
        setFundingSourceId(fundingSourceId);
        flowCustomizations?.onFSBankAccountAdded?.(fundingSourceId);
      },
    }),
    [flowCustomizations]
  );

  const onVerificationTypeSelected = useCallback(
    (selectedOption: VERIFICATION_OPTIONS) => {
      setIsManuallyCreated(selectedOption === VERIFICATION_OPTIONS.MANUALLY);
      goNextMap.select({ navArgs: [selectedOption] });
    },
    [goNextMap]
  );

  const onPlaidSuccess = useCallback(() => {
    if (isConnectedAccountingPlatform && !isDeliveryMethod) {
      history.push(getExtNavReturnUrl(STEPS.LINK_ACCOUNT, true)); // no choice since plaid change the history
    } else if (flowCustomizations.flowEntryPoint === 'guest' && skipBankSuccessStep) {
      setSkipSuccessStep(true);
    } else {
      history.push(getExtNavReturnUrl(STEPS.SUCCESS, true)); // no choice since plaid change the history
    }
  }, [getExtNavReturnUrl, history, isConnectedAccountingPlatform, isDeliveryMethod, skipBankSuccessStep]);

  const onPlaidFail = useCallback(() => {
    if (shouldGoBack) {
      history.push(getExtNavReturnUrl(STEPS.SELECT, true));
    } else {
      setIsManuallyFromPlaid(true);
      history.push(getExtNavReturnUrl(STEPS.MANUALLY, true)); // no choice since plaid change the history
    }
  }, [getExtNavReturnUrl, history, shouldGoBack]);

  const renderStep = () => {
    switch (currentStep) {
      case STEPS.SELECT:
        return (
          <SelectVerificationTypePageContainer
            flowCustomizations={enrichedFlowCustomizations}
            isDeliveryMethod={isDeliveryMethod}
            cancelFlow={cancelFlow}
            goBack={goBack}
            setSelectedVerificationType={onVerificationTypeSelected}
          />
        );
      case STEPS.PLAID:
        return (
          <AddPlaidBankAccountPage
            vendorId={vendorId}
            isDeliveryMethod={isDeliveryMethod}
            flowCustomizations={enrichedFlowCustomizations}
            onFail={onPlaidFail}
            onSuccess={onPlaidSuccess}
            isNewPlaidMDRecoveryFlow={!shouldGoBack}
          />
        );
      case STEPS.MANUALLY:
        return (
          <AddManuallyBankAccountPage
            vendorId={vendorId}
            isDeliveryMethod={isDeliveryMethod}
            flowCustomizations={enrichedFlowCustomizations}
            cancelFlow={cancelFlow}
            goNext={() => goNextMap.manually({ navArgs: [isConnectedAccountingPlatform, isDeliveryMethod] })}
            isManuallyFromPlaid={isManuallyFromPlaid}
          />
        );
      case STEPS.LINK_ACCOUNT:
        return (
          <LinkAccountingPlatform
            fundingSourceId={fundingSourceId}
            goNext={() => goNextMap['link-account']({ navArgs: [isManuallyCreated] })}
            cancelFlow={cancelFlow}
            flowCustomizations={flowCustomizations}
          />
        );
      case STEPS.PENDING:
        return (
          <PendingBankAccountsVerificationPage
            flowCustomizations={flowCustomizations}
            isDeliveryMethod={isDeliveryMethod}
            saveAndContinue={saveAndContinue}
          />
        );
      case STEPS.SUCCESS:
        return (
          <AddPlaidBankAccountSuccessPage
            flowCustomizations={flowCustomizations}
            isDeliveryMethod={isDeliveryMethod}
            saveAndContinue={saveAndContinue}
          />
        );
      default:
        return null;
    }
  };

  return renderStep();
};

import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { MIDropDown } from 'src/components/common/DropDown/MIDropDown';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { PasswordField, TextField } from 'src/core/ds/form/fields';
import { useDisclosure } from 'src/core/ds/hooks';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { VStack } from 'src/core/ds/stack';
import { NotificationVariant } from 'src/core/ds/toast';
import Tooltip from 'src/core/ds/tooltip';
import { useApi } from 'src/hoc/useApi';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { msnPortalApi } from 'src/modules/msn-portal/api';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { useBusinessTypeOptions } from 'src/pages/vendor/msn-candidates/hooks/useBusinessTypeOptions';
import { useTaxIdTypeOptions } from 'src/pages/vendor/msn-candidates/hooks/useTaxIdTypeOptions';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { useForm } from 'src/ui/form';
import { FormRow } from 'src/ui/form/FormElements';
import { convertToServerAddress } from 'src/utils/address';
import { AddressFormControlMode, TaxIdType } from 'src/utils/consts';
import { AddressType, GoogleCombinedAddressType, MsnBusinessTypeEnum } from 'src/utils/types';
import { useCandidateFormMessages } from '../../hooks/useCandidateFormMessages';
import { vendorCandidatesLocations } from '../../locations';
import { CandidateDetails, CandidatesDetailsModelType } from '../../types';
import { EmailAlreadyExistModal } from '../modals/EmailAlreadyExist';
import { model } from './consts';
import { formSectionTitleStyle } from './styles';
import { displayExistingEmailError, validateCandidatesDetails } from './utils';

export const CandidatesDetailsForm = () => {
  const site = useSiteContext();
  const { token } = useRouteMatch<{ token: string }>().params;
  const { messages: formattedMessagesIds } = useCandidateFormMessages(token);
  const [address, setAddress] = useState<GoogleCombinedAddressType | null>(null);
  const history = useHistory();
  const { navigate } = useNavigator();
  const { businessTypeOptions } = useBusinessTypeOptions();
  const { taxIdTypeOptions } = useTaxIdTypeOptions();
  const {
    isOpen: isEmailAlreadyExistModalOpen,
    onOpen: openEmailAlreadyExistModal,
    onClose: closeEmailAlreadyExistModal,
  } = useDisclosure();

  const { onApiCall: submitCandidateForm, loading: isLoading } = useApi<
    [string, CandidateDetails],
    Record<string, any>
  >({
    api: msnPortalApi.submitCandidateForm,
  });

  const [candidatesInfoMV, { submit }, modelValidationErrors] = useForm<CandidatesDetailsModelType>(model, {
    submit: async (value) => {
      const params: CandidateDetails = {
        email: value.email,
        companyName: value.companyName,
        firstName: value.contactFirstName,
        lastName: value.contactLastName,
        phone: value.phone,
        phoneExt: value.phoneExt,
        address: {
          googlePlaceId: value.googlePlaceId,
          addressLine1: value.addressLine1,
          addressLine2: value.addressLine2,
          aptNumber: value.aptNumber,
          state: value.state,
          zipCode: value.zipCode,
          countryCode: 'US',
          city: value.city,
          formattedAddress: value.formattedAddress,
        },
        bankAccount: {
          routingNumber: value.routingNumber,
          accountNumber: value.accountNumber,
        },
        msnBusinessType: value.msnBusinessType,
      };

      if (!isEmpty(value.taxId)) {
        params.taxId = value.taxId;
      }

      if (!isEmpty(value.taxIdType)) {
        params.taxIdType = value.taxIdType;
      }

      analytics.trackAction('msnSalesForm.submit.clicked', {
        email: value.email,
        businessType: value.msnBusinessType,
      });

      if (validateCandidatesDetails(value)) {
        try {
          await submitCandidateForm(token, params);
          history.push(generatePath(vendorCandidatesLocations.success, { token }));
        } catch (err: any) {
          const errorCode = err?.error?.code || err?.code;
          const isEmailAlreadyExist = errorCode === 'ATH01';
          const analyticsErrorInfo = isEmailAlreadyExist ? 'Email already exist' : 'Submission candidate failed';
          analytics.trackAction('msnSalesForm.submit.error', { error: analyticsErrorInfo });

          if (isEmailAlreadyExist) {
            openEmailAlreadyExistModal();
            displayExistingEmailError();
          } else {
            pushNotification({
              type: NotificationVariant.error,
              msg: 'server.ERR',
            });
          }
        }
      }
    },
  });

  const updateAddressModel = ({
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    countryCode,
    aptNumber,
    googlePlaceId,
    formattedAddress,
  }: AddressType) => {
    candidatesInfoMV.googlePlaceId.onChange({ value: googlePlaceId || '' });
    candidatesInfoMV.formattedAddress.onChange({ value: formattedAddress || '' });
    candidatesInfoMV.addressLine1.onChange({ value: addressLine1 || '' });
    candidatesInfoMV.addressLine2.onChange({ value: addressLine2 || '' });
    candidatesInfoMV.city.onChange({ value: city || '' });
    candidatesInfoMV.state.onChange({ value: state || '' });
    candidatesInfoMV.zipCode.onChange({ value: zipCode || '' });
    candidatesInfoMV.countryCode.onChange({ value: countryCode || '' });
    candidatesInfoMV.aptNumber.onChange({ value: aptNumber || '' });
  };

  const onAddressChange = (address: GoogleCombinedAddressType) => {
    if (address?.addressComponents?.length > 0) {
      const convertedAddress = convertToServerAddress(address);
      const { googlePlaceId, formattedAddress } = convertedAddress;

      if (googlePlaceId && formattedAddress) {
        updateAddressModel(convertedAddress);
      }

      setAddress(address);
    } else {
      updateAddressModel({});
      setAddress(null);
    }
  };

  const handleJoin = () => {
    navigate(generatePath(onboardingLocations.msnRegistration.register));
  };

  const handleBusinessTypeChange = ({ value }: { value: MsnBusinessTypeEnum }) => {
    candidatesInfoMV.msnBusinessType.onChange({ value });
  };

  const handleTaxIdTypeChange = ({ value }: { value: TaxIdType }) => {
    candidatesInfoMV.taxIdType.onChange({ value });
  };

  const onEmailConfirmationFieldPaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
  };

  return (
    <Box sx={{ px: { base: 4, md: 10 }, py: { base: 8 } }}>
      <VStack spacing={10}>
        <VStack spacing={2} alignItems="start">
          <Box textStyle="body1Semi">
            <MIFormattedText label={formattedMessagesIds.title} />
          </Box>
          <Box textStyle="body2">
            <MIFormattedText label={formattedMessagesIds.subTitle} />
          </Box>
        </VStack>
        <EmailAlreadyExistModal
          isOpen={isEmailAlreadyExistModalOpen}
          onClose={closeEmailAlreadyExistModal}
          onContinue={handleJoin}
        />
        <VStack spacing={10}>
          <VStack spacing={10} width="full">
            <FormRow>
              <TextField
                model={candidatesInfoMV.email}
                label="vendors.candidates.form.emailLabel"
                helperText="vendors.candidates.form.emailHelper"
                isRequired
              />
            </FormRow>
            <FormRow>
              <TextField
                model={candidatesInfoMV.emailConfirmation}
                label="vendors.candidates.form.emailConfirmationLabel"
                onPaste={onEmailConfirmationFieldPaste}
                autoComplete="off"
                isRequired
              />
            </FormRow>
            <FormRow>
              <TextField
                isRequired
                model={candidatesInfoMV.companyName}
                label="vendors.candidates.form.companyNameLabel"
              />
            </FormRow>
            <FormRow>
              <MIAddressAutocomplete
                id="address"
                addressLabel="vendors.candidates.form.businessAddressLabel"
                addressPlaceholder=""
                address={address}
                onChange={onAddressChange}
                errorMessage={modelValidationErrors?.googlePlaceId}
                required
                hideSuite
                mode={AddressFormControlMode.INLINE}
                marginBottomOverride={0}
              />
            </FormRow>
            <FormRow>
              <MIDropDown
                id="msnBusinessType"
                label="vendors.candidates.form.msnBusinessType"
                required
                value={candidatesInfoMV.msnBusinessType.value}
                options={businessTypeOptions}
                onChange={handleBusinessTypeChange}
                errorMessage={modelValidationErrors?.msnBusinessType}
                disableSearch
                testId="input-msnBusinessType"
              />
            </FormRow>
            <Box sx={formSectionTitleStyle}>
              <MIFormattedText label="vendors.candidates.form.bankDetailsSectionTitle" />
              <Tooltip
                label={<MIFormattedText label={formattedMessagesIds.bankDetailsSectionTooltip} />}
                placement="top"
              >
                <Icon ml="1" name={IconNames.infoCircle} size={IconSize.s} />
              </Tooltip>
            </Box>
            <FormRow>
              <TextField
                model={candidatesInfoMV.routingNumber}
                label="vendors.candidates.form.routingNumberLabel"
                isRequired
              />
              <TextField
                model={candidatesInfoMV.accountNumber}
                label="vendors.candidates.form.accountNumberLabel"
                isRequired
              />
            </FormRow>
            <FormRow>
              <MIDropDown
                id="taxIdType"
                value={candidatesInfoMV.taxIdType.value}
                options={taxIdTypeOptions}
                onChange={handleTaxIdTypeChange}
                errorMessage={modelValidationErrors?.taxIdType}
                disableSearch
                label="vendors.candidates.form.taxIdType.label"
                optionalLabelText="input.recommended"
                testId="input-taxIdType"
              />
              <PasswordField
                model={candidatesInfoMV.taxId}
                label="vendors.candidates.form.taxId.label"
                optionalLabelText="input.recommended"
                testId="input-taxId"
              />
            </FormRow>
            <Box sx={formSectionTitleStyle}>
              <MIFormattedText label="vendors.candidates.form.accountsReceivebleContactSection" />
              <Tooltip
                label={<MIFormattedText label="vendors.candidates.form.accountsReceivebleContactSectionTooltip" />}
                placement="top"
              >
                <Icon ml="1" name={IconNames.infoCircle} size={IconSize.s} />
              </Tooltip>
            </Box>
            <FormRow>
              <TextField
                model={candidatesInfoMV.contactFirstName}
                label="vendors.candidates.form.firstNameLabel"
                isRequired
              />
              <TextField
                model={candidatesInfoMV.contactLastName}
                label="vendors.candidates.form.lastNameLabel"
                isRequired
              />
            </FormRow>
            <FormRow>
              <TextField isRequired model={candidatesInfoMV.phone} label="vendors.candidates.form.phoneLabel" />
              <TextField model={candidatesInfoMV.phoneExt} label="vendors.candidates.form.phoneExtLabel" />
            </FormRow>
          </VStack>
          <VStack spacing={4}>
            <Button
              w="full"
              isLoading={isLoading}
              size={ButtonSizes.lg}
              onClick={submit}
              label="vendors.candidates.form.action"
            />
            {!isLoading && !isEmpty(modelValidationErrors) && (
              <Box textStyle="body4" color="red.500">
                <MIFormattedText label="vendors.candidates.form.validation.form" />
              </Box>
            )}
            <Box textStyle="caption1" color="grey.700">
              <MIFormattedText
                label={formattedMessagesIds.disclaimer}
                values={{
                  termsOfServiceLink: (...chunks) => (
                    <Box as="a" textStyle="link4" target="_blank" href={site.config.agreementLinks.userAgreement}>
                      {chunks}
                    </Box>
                  ),
                  privacyPolicyLink: (...chunks) => (
                    <Box as="a" textStyle="link4" target="_blank" href={site.config.agreementLinks.privacyPolicy}>
                      {chunks}
                    </Box>
                  ),
                }}
              />
            </Box>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
};

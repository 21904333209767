import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ListLayoutPage, ListPage, ViewSinglePage } from 'src/components/layout/ListLayoutPage';
import { DesktopListHeader } from 'src/components/list/DesktopListHeader';
import { usePagination } from 'src/core/components/pagination/hooks/usePagination';
import { Pagination } from 'src/core/components/pagination/Pagination';
import Box from 'src/core/ds/box';
import { Checkbox } from 'src/core/ds/checkbox';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useStaleAndLoad } from 'src/helpers/redux/useStaleAndLoad';
import { useBreak } from 'src/hoc';
import { useListSearchBarContext } from 'src/hoc/withListSearchBarContext';
import { useCanCreatePaymentRequest } from 'src/hooks/useCanCreatePaymentRequests';
import customersStore from 'src/modules/customers/customers-store';
import invoicesStore from 'src/modules/invoices/invoices-store';
import { InvoiceType } from 'src/modules/invoices/types';
import useGetAchDeliveryMethod from 'src/pages/get-paid/hooks/useGetAchDeliveryMethod';
import useIsSinglePage from 'src/pages/get-paid/hooks/useIsSinglePage';
import { useLoadInvoicesList } from 'src/pages/get-paid/hooks/useLoadInvoicesList';
import { useVerifyDeliveryMethodDialog } from 'src/pages/get-paid/hooks/useVerifyDeliveryMethodDialog';
import MobileBottomInfoCard from 'src/pages/get-paid/list/components/batchActions/MobileBottomInfoCard';
import ViewBatchInvoices from 'src/pages/get-paid/list/components/batchActions/ViewBatchInvoices';
import InvoicesList from 'src/pages/get-paid/list/components/InvoicesList';
import { Messages } from 'src/pages/get-paid/list/components/message/Messages';
import { getTotalAmount } from 'src/pages/get-paid/list/components/utils';
import ViewInvoice from 'src/pages/get-paid/list/components/ViewInvoice';
import { getOrderedGroupedInvoicesByTab } from 'src/pages/get-paid/list/group';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { callSinglePushNotification, getSelectedTab, getTabs, isSentOrUnSent } from 'src/pages/get-paid/utils';
import { useGetProEnabled } from 'src/pages/get-pro/hooks/useGetProEnabled';
import { getProLocations } from 'src/pages/get-pro/locations';
import { getOrgId, getOwnedVendorHandle } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { deserializePaymentId } from 'src/utils/bills';
import {
  GetProTabs,
  InvoiceStatus,
  NotificationVariant,
  PAGINATION,
  PaymentRequestStatus,
  PaymentRequestTabs,
} from 'src/utils/consts';
import { useQueryIds, useQueryState, useQueryString } from 'src/utils/hooks';
import { encodeQuery } from 'src/utils/query-utils';
import useGetPaidEmptyState from '../hooks/useGetPaidEmptyState';

const eventPage = 'invoice-list';

const InvoicesListPage = () => {
  const history = useHistory();
  const invoicesActions = useStoreActions(invoicesStore);
  const getProEnabled = useGetProEnabled();
  const { isDesktop } = useBreak();
  const listSelectors = invoicesStore.selectors.list;
  const orgId = useSelector(getOrgId);
  const query = useQueryString();
  const currentTab = getSelectedTab(query);
  const [{ contextSearchFilterValue: search }] = useListSearchBarContext();
  const [loadInvoices, listParams] = useLoadInvoicesList();
  const customerListParams = useMemo(() => ({ orgId }), [orgId]);
  const [customers] = useStaleAndLoad(customersStore, 'list', customerListParams);
  const pathname = generatePath(getPaidLocations.dashboard, { orgId });
  const { canCreatePaymentRequests } = useCanCreatePaymentRequest();
  const invoices = useSelector((state) => listSelectors.value(state, listParams));
  const [action, setAction] = useQueryState('action', undefined);
  const [actionParams, setActionParams] = useQueryState('action-params', undefined);

  const actionFetchListParams = useMemo(
    () => ({
      filters: {
        action,
        tab: currentTab,
        actionParams,
      },
      orgId,
      fromAction: true,
    }),
    [action, currentTab, orgId, actionParams]
  );

  const filteredInvoices: InvoiceType[] = useMemo(
    () => invoices.filter((invoice) => invoice.status !== InvoiceStatus.FAILED),
    [invoices]
  );
  const isAllMarked = useMemo(() => query.ids && query.ids.split(',').length === filteredInvoices.length, [
    filteredInvoices,
    query.ids,
  ]);
  const hasIds = !!query.ids;

  const loadingInvoices = useSelector(listSelectors.status(listParams))?.loading;
  const groupedInvoices = getOrderedGroupedInvoicesByTab(currentTab, invoices);

  const totalCount = useSelector(listSelectors.status(listParams))?.totalCount || 0;

  const { start = '0', limit = PAGINATION.DEFAULT_LIMIT.toString() } = query || {};

  const setPage = useCallback(
    (pageIndex: number) => {
      history.push({
        pathname: generatePath(getPaidLocations.dashboard, { orgId }),
        search: encodeQuery(
          {
            ...query,
            start: pageIndex * PAGINATION.DEFAULT_LIMIT,
            limit: PAGINATION.DEFAULT_LIMIT,
            status: currentTab,
            id: '',
          },
          [],
          ''
        ),
      });
    },
    [currentTab, history, orgId, query]
  );

  const {
    canNextPage,
    canPreviousPage,
    previousPage,
    nextPage,
    showPagination,
    pageIndex,
    setPaginationState,
  } = usePagination({
    totalItems: totalCount,
    setPage,
    start: parseInt(start, 10),
    limit: parseInt(limit, 10),
  });
  const vendorHandle = useSelector(getOwnedVendorHandle);
  const [markedIds, setMarked, setMarkedList, clearSelectedList] = useQueryIds();
  const tabs = getTabs(query);
  const [selectedInvoiceId, setSelectedId] = useQueryState('id', '');
  const { billId: invoiceId, paymentId } = deserializePaymentId(selectedInvoiceId);
  const [isSingleView] = useIsSinglePage();
  const invoicesById = useSelector<any, Record<number | string, InvoiceType>>((state) => state.invoices.byId);
  const isProcessingMarkedAsPaid = useSelector(invoicesStore.selectors.markedAsPaid.loading);
  const actionIds = useSelector(listSelectors.status(actionFetchListParams))?.actionIds;

  const selectedInvoices = useMemo(() => compact(markedIds.map((invoiceId) => invoicesById[invoiceId])), [
    invoicesById,
    markedIds,
  ]);
  const isSentOrUnSentTab = useMemo(() => isSentOrUnSent(currentTab), [currentTab]);
  const isMarkedAsPaid = !isEmpty(invoicesById[invoiceId]?.markedAsPaidAt);
  const numOfInvoicesSelected = selectedInvoices.length;
  const selectedInvoicesTotalAmount = getTotalAmount(selectedInvoices);

  useDeepCompareEffect(() => {
    const shouldSelectFirstItem = !query.id && isDesktop && !search && groupedInvoices.length;

    if (shouldSelectFirstItem) {
      setSelectedId(groupedInvoices[0].invoices[0].id);
    }
  }, [groupedInvoices, search, query, isDesktop, setSelectedId]);

  useEffect(() => {
    if (canCreatePaymentRequests) {
      loadInvoices();
    }
  }, [loadInvoices, canCreatePaymentRequests]);

  useEffect(() => {
    if (invoiceId) {
      const tab = isMarkedAsPaid ? PaymentRequestTabs.UNSENT : currentTab;
      invoicesActions.fetch({
        id: invoiceId,
        orgId,
        tab,
      });
    }
  }, [invoicesActions, orgId, invoiceId, currentTab, isMarkedAsPaid]);

  useEffect(() => {
    if (canCreatePaymentRequests) {
      invoicesActions.totalCounters({ orgId });
    }
  }, [orgId, invoicesActions, canCreatePaymentRequests]);

  useEffect(() => {
    if (currentTab) {
      setPaginationState(pageIndex);
    }
  }, [currentTab, setPaginationState, pageIndex]);

  useEffect(() => {
    if (action) {
      invoicesActions.list(actionFetchListParams);
    }
  }, [action]);

  useEffect(() => {
    if (actionIds && action) {
      if (actionIds.length > 0) {
        setMarkedList(actionIds);
      } else {
        pushNotification({
          type: NotificationVariant.INFO,
          msg: 'getPaid.view.invoice.invoicesAlreadySent',
        });
      }

      setAction(undefined);
      setActionParams(undefined);
    }
  }, [actionIds, action]);

  const manualDeliveryMethod = useGetAchDeliveryMethod();
  const deliveryMethodIdToVerify = manualDeliveryMethod?.id;
  const onVerifyFinished = async (success?: boolean) => {
    analytics.track(eventPage, 'verify-finish');

    if (success) {
      await loadInvoices();
    }

    history.push({
      pathname: generatePath(getPaidLocations.dashboard, { orgId }),
      search: encodeQuery(query, ['verifyDeliveryMethodId'], ''),
    });
  };

  // TODO: we need this to get prop for the empty state `shouldShowVerifyBankAccount` but we should consider making the ui work without it.
  const { shouldShowVerifyBankAccount } = useVerifyDeliveryMethodDialog({
    deliveryMethodIdToVerify: Number(deliveryMethodIdToVerify),
    onVerifyFinished,
  });

  const shouldShowVerifyBankAccountIndicator = isSentOrUnSentTab && shouldShowVerifyBankAccount;

  const [emptyState] = useGetPaidEmptyState({
    noInvoices: invoices.length === 0,
    showVerifyMdIndicator: shouldShowVerifyBankAccountIndicator,
    loadingInvoices,
    canCreatePaymentRequests,
  });

  const getItemLink = (id) => ({
    pathname,
    search: encodeQuery({ ...query, id }, [], ''),
  });
  const backLink = {
    pathname,
    search: encodeQuery(query, ['id'], ''),
  };

  const backLinkMobile = {
    pathname,
    search: encodeQuery(query, ['singlePageView'], ''),
  };

  const getProRedirectLink = () => {
    const { status, id } = query;

    if (status === PaymentRequestTabs.SCHEDULED && id) {
      return {
        pathname: generatePath(getProLocations.view, { orgId }),
        search: encodeQuery(
          {
            id: `${id}-payment`,
            status: GetProTabs.IN_PROGRESS,
            filter: 'all',
          },
          [],
          ''
        ),
      };
    }

    return generatePath(getProLocations.dashboard, { orgId });
  };

  const remove = async (removeInvoice = true) => {
    if (removeInvoice) {
      await invoicesActions.delete({ orgId, id: invoiceId });
    } else {
      await invoicesActions.cancelPaymentRequest({
        orgId,
        id: invoiceId,
      });
    }

    callSinglePushNotification(currentTab, removeInvoice);
    loadInvoices();
    await invoicesActions.totalCounters({ orgId });
    history.push(getItemLink(''));
  };

  const update = async (data) => {
    const result = await invoicesActions.update({ orgId, ...data });
    const id = result?.payload?.id;

    if (invoiceId !== id) {
      await loadInvoices();
      history.push(getItemLink(id));
    }
  };

  const share = () => {
    history.push({
      pathname: generatePath(getPaidLocations.create.share, { orgId }),
      search: encodeQuery({ id: invoiceId }, [], ''),
    });
  };

  const onMarkInvoiceAsPaid = async (markedAsPaid: boolean) => {
    const { payload } = await invoicesActions.markedAsPaid({
      orgId,
      id: invoiceId,
      markedAsPaid,
    });
    const updatedInvoice = payload.invoice;
    const paymentRequestTabByStatus =
      updatedInvoice.status === PaymentRequestStatus.PENDING ? PaymentRequestTabs.SENT : PaymentRequestTabs.UNSENT;
    history.push({
      pathname: generatePath(getPaidLocations.dashboard, { orgId }),
      search: encodeQuery(
        {
          id: selectedInvoiceId,
          status: markedAsPaid ? PaymentRequestTabs.PAID : paymentRequestTabByStatus,
        },
        [],
        ''
      ),
    });
  };

  const onSetSelected = (value: string) => isEmpty(markedIds) && setSelectedId(value);
  const unSelectWithCallback = async (callback) => {
    await setSelectedId('');

    callback();
  };

  const onSelectAll = () => {
    if (!isAllMarked) {
      onMarkAll();
    } else {
      clearSelectedList();
    }
  };

  const onSetMarked = (id: string, value: boolean) =>
    unSelectWithCallback(() => {
      setMarked(id, value);
    });

  const onMarkAll = () => unSelectWithCallback(() => setMarkedList(map(filteredInvoices, 'id')));

  const checkBoxLabel = useMemo(
    () => (isAllMarked ? 'getPaid.view.batch.checkboxes.clear' : 'getPaid.view.batch.checkboxes.selectAll'),
    [isAllMarked]
  );

  const actionsRow = hasIds && groupedInvoices.length > 0 && (
    <ActionsRow>
      <Checkbox isChecked={hasIds} onChange={onSelectAll} isIndeterminate={!isAllMarked}>
        <MIFormattedText label={checkBoxLabel} />
      </Checkbox>
    </ActionsRow>
  );

  if (isDesktop && getProEnabled) {
    return <Redirect to={getProRedirectLink()} />;
  }

  const isEmptyUnsentTab =
    !totalCount && (currentTab === PaymentRequestTabs.UNSENT || currentTab === PaymentRequestTabs.SENT);
  const showNewItemButton = !(isEmptyUnsentTab || !canCreatePaymentRequests);

  return (
    <ListLayoutPage eventPage={eventPage} title="requests.title" showNewItemButton={showNewItemButton}>
      <ListPage>
        <ListPageContainer isSingleView={isSingleView && hasIds}>
          <DesktopListHeader tabs={tabs} />
          {hasIds && canCreatePaymentRequests && isSentOrUnSentTab && actionsRow}
          <Messages onVerify={onVerifyFinished} isSentOrUnSentTab={isSentOrUnSentTab} />
          {emptyState}
          {canCreatePaymentRequests && (
            <InvoicesList
              enableMultiSelect={isSentOrUnSentTab}
              selectedId={!isSentOrUnSentTab || isEmpty(markedIds) ? selectedInvoiceId : undefined}
              setSelected={onSetSelected}
              markedIds={markedIds}
              setMarked={onSetMarked}
              search={search}
              groupedInvoices={{ ...groupedInvoices }}
            />
          )}
          {showPagination && (
            <Box
              ml="auto"
              float={{
                base: 'left',
                md: 'right',
              }}
              py={4}
              h="4.4rem"
            >
              <Pagination
                pageIndex={pageIndex}
                totalItems={totalCount}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageItemCount={PAGINATION.DEFAULT_LIMIT}
              />
            </Box>
          )}
          {isSingleView && hasIds && (
            <MobileBottomInfoCard numOfInvoices={numOfInvoicesSelected} totalAmount={selectedInvoicesTotalAmount} />
          )}
        </ListPageContainer>
      </ListPage>
      <ViewSinglePage>
        {groupedInvoices.length > 0 && selectedInvoices.length > 0 ? (
          <ViewBatchInvoices invoices={selectedInvoices} backLink={backLinkMobile} />
        ) : (
          <ViewInvoice
            invoice={invoices.find((invoice) => invoice.id === invoiceId)}
            vendorHandle={vendorHandle}
            update={update}
            share={share}
            remove={remove}
            onMarkInvoiceAsPaid={onMarkInvoiceAsPaid}
            isProcessingMarkedAsPaid={isProcessingMarkedAsPaid}
            backLink={backLink}
            customers={customers}
            currentPaymentId={paymentId}
          />
        )}
      </ViewSinglePage>
    </ListLayoutPage>
  );
};

export { InvoicesListPage };
const ListPageContainer = styled.div<{ isSingleView?: boolean }>`
  margin-bottom: ${(props) => (props.isSingleView ? 18 : 0)}rem;
`;

const ActionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 2rem;
`;

import { isNumber } from 'lodash';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApi } from 'src/hoc/useApi';
import { authApi, MfaVerifyCodePayloadType } from 'src/modules/auth/api';
import { globalLocations } from 'src/pages/locations';
import { intercomService } from 'src/services/intercom';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import { MfaAuthenticatorMethodType, MfaAuthenticatorType, UserContextType } from 'src/utils/types';
import { uuid } from 'src/utils/uuid';
import {
  mapAnalyticErrorTypes,
  mapMfaSuccessResendPushNotifications,
  MfaAuthenticatorMethod,
  MfaCodeVerificationState,
} from '../consts';
import { useMfaCodeVerificationAnalytics } from './useMfaCodeVerificationAnalytics';
import { useMfaDetails } from './useMfaDetails';

type Props = {
  user: Partial<UserContextType>;
  onSuccess: VoidFunction;
  setResetMfaCodeKey: (code: string) => void;
};

type ReturnValue = {
  isVerifying: boolean;
  authenticator?: MfaAuthenticatorType;
  isMoreThanOneAuthenticator: boolean;
  authVerificationCodeStatus: MfaCodeVerificationState;
  isLastResendAttemptDone: boolean;
  isLastVerificationAttemptDone: boolean;
  onPrev: VoidFunction;
  verifyCode: (code: string) => void;
  onStartOver: VoidFunction;
  handleResendCodeClick: VoidFunction;
  handleSendToPhoneClick: VoidFunction;
  handleSendToEmailClick: VoidFunction;
  handleContactSupportClick: VoidFunction;
  resetVerificationCodeStatus: VoidFunction;
};

export const useMfaCodeVerification = ({ user, onSuccess, setResetMfaCodeKey }: Props): ReturnValue => {
  const history = useHistory();
  const { mfaToken, authenticators, lastChallengedAuthenticator, isMoreThanOneAuthenticator } = useMfaDetails();

  const [verificationType, setVerificationType] = useState<MfaAuthenticatorMethodType>(
    () => lastChallengedAuthenticator?.type
  );
  const [authVerificationCodeStatus, setAuthVerificationCodeStatus] = useState<MfaCodeVerificationState>(
    MfaCodeVerificationState.PENDING
  );
  const [remainingResendAttempts, setRemainingResendAttempts] = useState<number>(2);
  const [remainingVerificationAttempts, setRemainingVerificationAttempts] = useState<number>(2);

  const { onApiCall: verifyMfaToken, loading: isVerifying } = useApi<
    [data: MfaVerifyCodePayloadType],
    Record<string, any>
  >({
    api: authApi.verifyMfaToken,
  });

  const { onApiCall: resendMfaCode, loading: isResending } = useApi<
    [authenticatorId: string, mfaToken: string],
    Record<string, any>
  >({
    api: authApi.resendMfaCode,
  });

  const authenticator = authenticators?.find((el) => el.type === verificationType);
  const phoneAuthenticator = authenticators?.find((el) => el.type === MfaAuthenticatorMethod.SMS);
  const emailAuthenticator = authenticators?.find((el) => el.type === MfaAuthenticatorMethod.EMAIL);
  const authenticatorId = authenticator?.id || '';
  const isLastVerificationAttemptDone = remainingVerificationAttempts === 0;
  const isLastResendAttemptDone = remainingResendAttempts === 0;

  const analytics = useMfaCodeVerificationAnalytics({
    user,
    authenticatorType: verificationType,
    isMoreThanOneAuthenticator,
  });

  const onPrev = () => {
    analytics.trackOnPrev();
    history.push(globalLocations.auth.login);
  };

  const onStartOver = () => {
    analytics.trackStartOver();
    history.push(globalLocations.auth.login);
  };

  const onFailure = ({
    errorCode,
    errorMessage,
    remainingAttempts,
    remainingCodeChallengeAttempts,
    remainingCodeVerificationAttempts,
  }) => {
    analytics.trackCodeEntered(
      false,
      errorMessage,
      errorCode,
      remainingAttempts,
      remainingCodeChallengeAttempts,
      remainingCodeVerificationAttempts
    );
    setAuthVerificationCodeStatus(errorCode);
    isNumber(remainingCodeChallengeAttempts) && setRemainingResendAttempts(remainingCodeChallengeAttempts);
    isNumber(remainingCodeVerificationAttempts) && setRemainingVerificationAttempts(remainingCodeVerificationAttempts);
  };

  const verifyCode = useCallback(
    async (code: string) => {
      setRemainingVerificationAttempts(remainingVerificationAttempts - 1);
      try {
        const { response } = await verifyMfaToken({
          auth_code: code,
          authenticator_id: authenticatorId,
          mfa_token: mfaToken,
        });

        const errorCode = response?.code || null;
        const errorMessage = mapAnalyticErrorTypes[errorCode] || null;
        const remainingAttempts = response?.data?.remainingAttempts;
        const remainingCodeChallengeAttempts = response?.data?.remainingCodeChallengeAttempts;
        const remainingCodeVerificationAttempts = response?.data?.remainingCodeVerificationAttempts;

        if (!errorCode) {
          setAuthVerificationCodeStatus(MfaCodeVerificationState.VERIFIED);
          analytics.trackCodeEntered(true);
          onSuccess();

          return;
        }

        onFailure({
          errorCode,
          errorMessage,
          remainingAttempts,
          remainingCodeChallengeAttempts,
          remainingCodeVerificationAttempts,
        });
      } catch {
        onFailure({
          errorCode: MfaCodeVerificationState.FORBIDDEN,
          errorMessage: null,
          remainingAttempts: null,
          remainingCodeChallengeAttempts: null,
          remainingCodeVerificationAttempts: null,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authenticatorId]
  );

  const resetVerificationCodeStatus = () => {
    setAuthVerificationCodeStatus(MfaCodeVerificationState.PENDING);
  };

  const resendCodeRequest = async (authenticatorId: string, verificationType: MfaAuthenticatorMethod) => {
    analytics.trackResendClicked();
    try {
      resetVerificationCodeStatus();
      setResetMfaCodeKey(uuid());
      const { response } = await resendMfaCode(authenticatorId, mfaToken);
      const errorCode = response?.code || null;
      const remainingCodeChallengeAttempts = response?.remainingCodeChallengeAttempts;
      isNumber(remainingCodeChallengeAttempts) && setRemainingResendAttempts(remainingCodeChallengeAttempts);

      const isMaxResendAttempts = errorCode === MfaCodeVerificationState.MAX_ATTEMPTS_REACHED;
      const shouldShowErrorNotification = [
        MfaCodeVerificationState.EXPIRED_MFA_SESSION,
        MfaCodeVerificationState.MAX_ATTEMPTS_REACHED,
      ].includes(errorCode);

      if (errorCode) {
        if (shouldShowErrorNotification) {
          setAuthVerificationCodeStatus(
            isMaxResendAttempts ? MfaCodeVerificationState.MAX_RESEND_ATTEMPTS_REACHED : errorCode
          );
        } else {
          pushNotification({
            type: NotificationVariant.ERROR,
            msg: 'server.ERR',
          });
        }
      } else {
        pushNotification({
          type: NotificationVariant.SUCCESS,
          msg: mapMfaSuccessResendPushNotifications[verificationType],
        });
      }
    } catch {
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'server.ERR',
      });
    }
  };

  const handleResendCodeClick = useCallback(async () => {
    await resendCodeRequest(authenticatorId, verificationType);
    setRemainingVerificationAttempts(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatorId, verificationType]);

  const handleChangeVerificationType = async (authenticatorId: string, verificationType: MfaAuthenticatorMethod) => {
    await resendCodeRequest(authenticatorId, verificationType);
  };

  const handleSendToPhoneClick = async () => {
    analytics.trackSendToPhoneClicked();
    setVerificationType(MfaAuthenticatorMethod.SMS);
    phoneAuthenticator?.id && (await handleChangeVerificationType(phoneAuthenticator.id, MfaAuthenticatorMethod.SMS));
  };

  const handleSendToEmailClick = async () => {
    analytics.trackSendToEmailClicked();
    setVerificationType(MfaAuthenticatorMethod.EMAIL);
    emailAuthenticator?.id && (await handleChangeVerificationType(emailAuthenticator.id, MfaAuthenticatorMethod.EMAIL));
  };

  const handleContactSupportClick = () => {
    analytics.trackSupportClicked();
    intercomService.show();
  };

  return {
    isVerifying: isVerifying || isResending,
    authenticator,
    isMoreThanOneAuthenticator,
    authVerificationCodeStatus,
    isLastResendAttemptDone,
    isLastVerificationAttemptDone,
    onPrev,
    verifyCode,
    onStartOver,
    handleResendCodeClick,
    handleSendToPhoneClick,
    handleSendToEmailClick,
    handleContactSupportClick,
    resetVerificationCodeStatus,
  };
};

import { featureFlags } from '@melio/shared-web';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore from 'src/modules/users/users-store';
import {
  AssignClientsModalMessage,
  useAssignClientsState,
  UserType,
} from 'src/pages/team/team-management/AssignClientsModal';
import { analytics } from 'src/services/analytics';
import { FeatureFlags } from 'src/utils/consts';
import { CompanyInfoType } from 'src/utils/types';

type Props = {
  dismiss: (event?: React.MouseEvent<Element, MouseEvent>) => void;
  orgId: number;
  targetUserId: number;
};

export function ManageClientsModal({ dismiss, orgId, targetUserId }: Props) {
  const [approvalWorkflowsEnabled] = featureFlags.useFeature(FeatureFlags.AprovalWorkflows, false);
  const actions = useStoreActions(usersStore);
  const actor = useSelector(profileStore.selectors.profile);

  const assignToUser = useSelector(usersStore.selectors.byId(targetUserId)) as UserType;

  const organizations = useSelector(profileStore.selectors.getOrganizations) as Pick<
    CompanyInfoType,
    'id' | 'companyName'
  >[];

  const isLoading: boolean = useSelector(usersStore.selectors.editUserOrganizations.loading);

  const { clients, getAssignedUserOrgs, validate } = useAssignClientsState({
    actorUserOrgs: actor?.userOrganizations || [],
    assignToUser,
    organizations,
    firmOrgId: orgId,
  });

  const handleSave = async () => {
    const assignedUserOrgs = getAssignedUserOrgs();

    analytics.trackAction('manage-clients-submit', {
      targetUserId,
      userOrganizations: assignedUserOrgs,
    });

    if (!approvalWorkflowsEnabled) {
      try {
        validate(actor?.userOrganizations, 'userOrganization');
      } catch (error: any) {
        if (error.type === 'validation') {
          analytics.trackAction('form-validation-error', {
            validationErrors: error.errors,
          });

          return;
        }

        throw error;
      }
    }

    try {
      await actions.editUserOrganizations({
        orgId,
        targetUserId,
        items: assignedUserOrgs,
      });
      dismiss();
    } catch (error) {
      dismiss();
      throw error;
    }
  };

  return (
    <AssignClientsModalMessage
      showApprovalFields={false}
      actorUserOrgs={actor?.userOrganizations || []}
      titleLabel="team.modals.assignClients.title"
      isLoading={isLoading}
      onCloseClick={dismiss}
      clients={clients}
      firstName={assignToUser?.firstName || ''}
      lastName={assignToUser?.lastName || ''}
      onSave={handleSave}
    />
  );
}

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { getOrgIdByUrl } from 'src/helpers/utilities';
import { useApi } from 'src/hoc/useApi';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { authApi, login } from 'src/modules/auth/api';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { useSignInScreenName } from 'src/pages/auth/hooks/useSignInScreenName';
import { authLocations } from 'src/pages/auth/locations';
import { useMfaDetails } from 'src/pages/auth/multi-factor-authentication/hooks/useMfaDetails';
import { registrationLocations } from 'src/pages/auth/registration/locations';
import { sessionStorageUpdateAndReportEventOnLogin } from 'src/pages/auth/utils';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { initIntercomUserAction, initUserAction, setProfileAction } from 'src/redux/user/actions';
import { analytics } from 'src/services/analytics';
import { hasKeys } from 'src/utils/hasKey';
import { useLocationState } from 'src/utils/hooks';
import { isArray } from 'src/utils/isArray';
import { PaymentRequestType, UserContextType, UserPreferencesType } from 'src/utils/types';
import { getRedirectLoginUrl } from 'src/utils/user';
import { JsonValue } from 'src/utils/utility-types';
import { LoginFlow, RegistrationFlow } from '../../../utils/consts';
import { spendManagementLocations } from '../../spend-management/locations';
import { MfaCodeVerificationState } from '../multi-factor-authentication/consts';
import { LoginFlowType, useLoginFlowType } from '../multi-factor-authentication/hooks/useLoginFlowType';

type ReturnValue = {
  onLogin: (v) => void;
  initUserAndNavigate: (user: UserContextType, eventName: string, joinNewOrgFlowUrl?: string) => Promise<void>;
  isLoading: boolean;
  errorCode: string | null;
  validationErrors: Record<string, string | undefined>;
};

export const PreservedState = {
  link: '',
  paymentRequest: {
    customerEmail: '',
    customerName: '',
    link: '',
  },
};

export type PreservedStateType = {
  link: string;
  paymentRequest: {
    customerEmail: string;
    customerName: string;
    link: string;
  };
};

function isPaymentRequest(
  maybePaymentRequest: JsonValue | undefined
): maybePaymentRequest is Pick<PaymentRequestType, 'customerEmail' | 'customerName' | 'link'> {
  return (
    isObject(maybePaymentRequest) &&
    !isArray(maybePaymentRequest) &&
    hasKeys(maybePaymentRequest, 'customerEmail', 'customerName', 'link')
  );
}

export const useLoginUser = (): ReturnValue => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});
  const { onApiCall: guestRegister } = useApi<
    [{ email: string; password: string; registrationFlow: string }],
    Record<string, UserContextType>
  >({
    api: authApi.guestRegister,
  });

  const site = useSiteContext();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { setMfaDetails } = useMfaDetails();
  const { setLoginFlowType } = useLoginFlowType();
  const { navigateWithPreservedState, navigate } = usePreservedStateNavigator();

  const [redirectUrl] = useLocationState('redirectUrl', '');
  const [preservedState] = useLocationState('preservedState', PreservedState);
  const [loginFlow] = useLocationState<string>('loginFlow');

  const { screenName } = useSignInScreenName();

  const isSpendManagementLoginFlow = loginFlow === LoginFlow.SPEND_MANAGEMENT;

  const eventPage = isSpendManagementLoginFlow ? 'spend-management-login' : 'login';

  const onInitUserAction = useCallback(
    async (user: UserContextType) => {
      const orgIdFromUrl = getOrgIdByUrl();

      if (orgIdFromUrl && orgIdFromUrl !== user.orgId) {
        const orgIdfromUrlBelongsToUser = user.organizations.find((org) => org.id === orgIdFromUrl);

        if (orgIdfromUrlBelongsToUser) {
          user.orgId = orgIdFromUrl;
        }
      }

      return new Promise((resolve, reject) => {
        dispatch(initUserAction(user, false, resolve, reject));
      });
    },
    [dispatch]
  );

  const initUserAndNavigate = useCallback(
    async (userDetails: UserContextType, eventName: string, joinNewOrgFlowUrl?: string) => {
      setIsLoading(true);

      if (userDetails.isGuest) {
        navigate(onboardingLocations.companyInfo.address);
      } else if (joinNewOrgFlowUrl) {
        navigate(joinNewOrgFlowUrl);
      } else if (userDetails.registrationFlow === RegistrationFlow.SPEND_MANAGEMENT || isSpendManagementLoginFlow) {
        history.push(generatePath(spendManagementLocations.index, { orgId: userDetails.orgId }));
      } else if (navigateWithPreservedState) {
        if (
          preservedState &&
          isPaymentRequest(preservedState.paymentRequest) &&
          redirectUrl.includes(`meliome/pay/${preservedState.link}/edit-funding-source`) &&
          userDetails.organizations.length > 1
        ) {
          const { customerEmail, customerName } = preservedState.paymentRequest;
          let redirectUrlToPaymentRequest;

          if (customerEmail && customerName) {
            redirectUrlToPaymentRequest = generatePath(melioMeLocations.wizard.requestPayment, {
              link: preservedState.link,
              hash: preservedState.paymentRequest.link,
            });
          } else {
            redirectUrlToPaymentRequest = generatePath(melioMeLocations.wizard.index, { link: preservedState.link });
          }

          navigate(redirectUrlToPaymentRequest);
        } else {
          navigateWithPreservedState();
        }
      } else if (
        location.pathname === authLocations.login ||
        location.pathname === registrationLocations.authCodeVerification
      ) {
        const redirectUrl = getRedirectLoginUrl({
          orgId: userDetails.orgId,
          registrationFlow: userDetails.registrationFlow,
          userPreferences: userDetails.userPreferences as UserPreferencesType,
          organizations: userDetails.organizations,
        });

        navigate(redirectUrl);
      }

      await onInitUser(userDetails, eventName);
    },
    [isSpendManagementLoginFlow]
  );

  const onLogin = useCallback(
    async ({ email, password }) => {
      let validationErrors;

      if (isEmpty(email)) {
        validationErrors = { email: 'inputErrors.userRegistration.email.string.empty' };
      }

      if (isEmpty(password)) {
        validationErrors = { ...validationErrors, password: 'inputErrors.userRegistration.password.string.empty' };
      }

      setValidationErrors(validationErrors);

      if (isEmpty(validationErrors)) {
        try {
          setIsLoading(true);
          setErrorCode(null);
          analytics.track(eventPage, 'continue');

          const { user } = await login({ email, password });
          await dispatch(setProfileAction({ ...user }));
          await setLoginFlowType(LoginFlowType.EmailPassword);
          await dispatch(initIntercomUserAction(user));
          await analytics.identify(user);
          const isJoinNewOrgFlow = location.pathname.includes('companies/accept-request/');

          if (user.isGuest) {
            setIsLoading(true);
            const data = await guestRegister({
              email,
              password,
              registrationFlow: site.createOrigin.meliome.payor as string,
            });

            dispatch(setProfileAction({ ...data.user }));
            setIsLoading(false);
          }

          if (!isNil(user.hasValid2Factor) && !user.hasValid2Factor) {
            user?.mfa && setMfaDetails(user.mfa);

            if (user?.mfa?.code === MfaCodeVerificationState.DENIED) {
              analytics.track(eventPage, 'continue-failed', { error: 'mfa-denied' });
              setIsLoading(false);
              setErrorCode(user?.mfa?.code);
            } else {
              navigate(authLocations.register.authCodeVerification, true, {
                joinNewOrgFlowUrl: isJoinNewOrgFlow ? location.pathname : '',
              });
            }
          } else {
            await initUserAndNavigate(user, 'continue-success');
          }
        } catch (e: any) {
          analytics.track(eventPage, 'continue-failed', { error: omit(e, 'requestData') });
          setIsLoading(false);
          setErrorCode(e.code);
        }
      } else {
        analytics.track(eventPage, 'validation-error', validationErrors);
      }
    },
    [initUserAndNavigate]
  );

  const onInitUser = async (user, eventName) => {
    try {
      await onInitUserAction(user);
      analytics.track(eventPage, eventName, { screenName }, { integrations: { Salesforce: true } });
      setIsLoading(false);
      sessionStorageUpdateAndReportEventOnLogin(user.id, user.orgId);
    } catch (e: any) {
      analytics.track(eventPage, 'continue-failed', { error: omit(e, 'requestData') });
      setIsLoading(false);
      setErrorCode(e.code);
    }
  };

  return {
    onLogin,
    initUserAndNavigate,
    isLoading,
    errorCode,
    validationErrors,
  };
};

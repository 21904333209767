import { getValidationErrors } from '@melio/sizzers-js-common';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import { PasswordField, TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks/usePayablesConnectedAccountingPlatform';
import { financialAccountsApi } from 'src/modules/funding-sources/api';
import { useRefreshFundingSources } from 'src/modules/funding-sources/hooks/useRefreshFundingSources';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { vendorsApi } from 'src/modules/vendors/api';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { selectFundingSourceAction } from 'src/redux/payBillWizard/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications/notificationService';
import { useForm } from 'src/ui/form';
import { ValidationError } from 'src/ui/ValidationError';
import { WizardFormContainer } from 'src/ui/wizard/WizardFormContainer';
import {
  AddFundingSourceWizardOrigin,
  BankAccountType,
  DbAnalyticsTraits,
  NotificationVariant,
} from 'src/utils/consts';
import locations from 'src/utils/locations';
import { checkApostropheForPluralPossessive } from 'src/utils/string-utils';
import { BankType, DeliveryMethodType } from 'src/utils/types';

/**
 * @deprecated please do not use this - use src/flows/add-bank-account-flow/steps/manual-account/AddManuallyBankAccountPage.tsx
 */
const SetManuallyBankAccountPageContainer = () => {
  const { navigate, navigateToExitWithPreservedState } = usePreservedStateNavigator();
  const { state: locationState } = useLocation<Record<string, any>>();
  const intl = useIntl();
  const orgId = useSelector(getOrgId);
  const { isConnected: isConnectedAccountingPlatform } = usePayablesConnectedAccountingPlatform();
  const dispatch = useDispatch();
  const batchActions = useStoreActions(regularBatchPaymentsStore);
  const { onApiCall: requestMicroDeposit, result, loading: isLoading, error } = useApi({
    api: financialAccountsApi.requestMicroDeposit,
  });
  const { onApiCall: getVendorsDeliveryMethods, loading: isValidating } = useApi({
    api: vendorsApi.getVendorsDeliveryMethods,
  });
  const { refreshFundingSources, isFundingSourcesRefreshing } = useRefreshFundingSources();

  const model = useMemo(
    () => ({
      routingNumber: '',
      accountNumber: '',
    }),
    []
  );

  const goExit = () => {
    if (navigateToExitWithPreservedState) {
      navigateToExitWithPreservedState(locationState);
    } else {
      navigate(locations.MainApp.dashboard.url());
    }
  };

  const submitManualBankAccount = async (value) => {
    const bank = {
      ...value,
      accountType: BankAccountType.CHECKING,
    };
    const validationErrors = await validateBankAccount(bank);

    if (isEmpty(validationErrors)) {
      analytics.trackAction('funding-source-added-manually');
      try {
        await requestMicroDeposit(orgId, { bank });
        // eslint-disable-next-line no-empty
      } catch (e) {}
    } else {
      throw new ValidationError({ validationErrors });
    }
  };

  const onMicroDepositCreated = useCallback(
    async ({ fundingSource }) => {
      const origin = locationState?.preservedState?.origin || '';
      await refreshFundingSources();
      analytics.setTraits({ [DbAnalyticsTraits.ADDED_FUNDING]: true });
      analytics.setFundingSourceTraits();
      analytics.trackAction('funding-source-added-manually-success');
      analytics.trackMqlEvent('added-funding', 'mql');
      analytics.trackMqlEvent('added-funding-manual', 'mql');

      if (origin === AddFundingSourceWizardOrigin.PAY_BILL) {
        dispatch(selectFundingSourceAction(fundingSource.id));
      }

      if (origin === AddFundingSourceWizardOrigin.BATCH_PAY_BILLS) {
        const selectedIds = get(locationState, 'preservedState.selectedIds', []);
        await batchActions.settings.update({
          scheduledDate: null,
          fundingSourceId: fundingSource.id,
          billIds: selectedIds,
        });
      }

      if (isConnectedAccountingPlatform) {
        navigate(onboardingLocations.fundingSources.bank.account, false, {
          ...locationState,
          fundingSourceId: fundingSource.id,
          origin,
        });
      } else {
        navigate(onboardingLocations.fundingSources.bank.complete, false, {
          ...locationState,
          newFundingSourceId: fundingSource.id,
          origin,
        });
      }
    },
    [locationState, refreshFundingSources, isConnectedAccountingPlatform, dispatch, batchActions.settings, navigate]
  );

  useEffect(() => {
    if (error) {
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: `server.${error.code}`,
      });
    }
  }, [error]);

  useEffect(() => {
    if (result) {
      onMicroDepositCreated(result);
    }
  }, [result]);

  const validateBankAccount = async (bank: BankType) => {
    const { routingNumber, accountNumber } = bank;
    const syncValidationErrors = getValidationErrors('fundingSourceBank', bank, [
      'accountType',
      'routingNumber',
      'accountNumber',
    ]);

    if (!isEmpty(syncValidationErrors)) {
      return syncValidationErrors;
    }

    const { vendorsDeliveryMethods } = await getVendorsDeliveryMethods(orgId);
    const deliveryMethod = vendorsDeliveryMethods.find((deliveryMethodType: DeliveryMethodType) => {
      const { bankAccount } = deliveryMethodType;

      return bankAccount?.routingNumber === routingNumber && bankAccount?.accountNumber === accountNumber;
    });

    if (deliveryMethod?.vendor) {
      // TODO: add the possiblity to pass values in error messages for useForm
      return {
        accountNumber: intl.formatMessage(
          { id: 'inputErrors.fundingSourceBank.alreadyDefined' },
          {
            vendorName: checkApostropheForPluralPossessive(deliveryMethod.vendor.companyName),
          }
        ),
      };
    }

    return {};
  };

  const [bankAccountVM, { submit }] = useForm(model, {
    submit: submitManualBankAccount,
    onChange: ({ key, value, modelState }) => {
      const regex = /(^[0-9]+$|^$)/;

      if (key === 'accountNumber' && !regex.test(value)) {
        return { ...modelState, accountNumber: value.slice(0, -1) };
      }

      return modelState;
    },
  });

  return (
    <StepLayoutPage
      title="flows.addBankAccount.manually.title"
      subtitle="flows.addBankAccount.manually.subtitle"
      goExit={goExit}
      onSubmit={submit}
      nextLabel="flows.addBankAccount.manually.completeAndSave"
      isLoading={isLoading || isFundingSourcesRefreshing || isValidating}
      footer={
        <WizardElements.SimpleTextFooterSlim>
          <MIFormattedText label="onboarding.fundingSources.bank.manually.saveFooter" />
        </WizardElements.SimpleTextFooterSlim>
      }
      hideHeader
      fullWidthCTA
    >
      <WizardFormContainer>
        <PrivateDataContainer>
          <TextField
            testId="input-routingNumber"
            model={bankAccountVM.routingNumber}
            label="onboarding.fundingSources.bank.manually.routingNumber"
            type="tel"
            autoFocus
            isRequired
          />
        </PrivateDataContainer>

        <PasswordField
          testId="input-accountNumber"
          model={bankAccountVM.accountNumber}
          label="onboarding.fundingSources.bank.manually.accountNumber"
          isRequired
          shouldShowValue
        />
        <HiddenInput type="password" autoComplete="new-password" />
      </WizardFormContainer>
    </StepLayoutPage>
  );
};

export default SetManuallyBankAccountPageContainer;

const HiddenInput = styled.input`
  display: none;
`;

import { ChangeEvent } from 'react';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { MaskField, PasswordField, RadioGroupField, TextField } from 'src/core/ds/form/fields';
import { Consts, PrivateDataContainer } from 'src/core/ds/input';
import { Radio } from 'src/core/ds/radio';
import { getTaxIdTypesOptions } from 'src/utils/company';
import {
  AddressFormControlMode,
  CompanyFormPage,
  FORM_AUTOCOMPLETE,
  FormType,
  ScreenMode,
  TaxIdType,
} from 'src/utils/consts';
import { FieldType, GoogleCombinedAddressType } from 'src/utils/types';

type Props = {
  legalCompanyName: string | null;
  address: GoogleCombinedAddressType;
  taxId?: string;
  taxIdType?: TaxIdType;
  mode?: ScreenMode;
  onChange: (field: FieldType) => void;
  onAddressChange: (arg0: GoogleCombinedAddressType) => void;
  formType?: FormType;
  validationErrors: Record<string, string>;
  companyFormPage: CompanyFormPage;
  isAutofocus?: boolean;
  showTaxIdTypeNotice?: boolean;
  isCompanyLegalInfoMissed?: boolean;
  showDateOfBirth?: boolean;
  dateOfBirth?: string;
};

export const LegalInfoForm = ({
  legalCompanyName,
  address,
  formType = FormType.WIZARD,
  taxId = '',
  taxIdType,
  mode = ScreenMode.EDIT,
  isAutofocus = false,
  onChange,
  onAddressChange,
  validationErrors,
  companyFormPage,
  showTaxIdTypeNotice = false,
  isCompanyLegalInfoMissed = true,
  showDateOfBirth,
  dateOfBirth,
}: Props) => {
  const isViewOnly = mode === ScreenMode.VIEW;

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    onChange({ id, value });
  };

  return (
    <PrivateDataContainer>
      {isCompanyLegalInfoMissed ? (
        <>
          <TextField
            id="legalCompanyName"
            label="bills.pay.confirm.completeLegalInfo.legalName.inputTitle"
            value={legalCompanyName || ''}
            onChange={handleFieldChange}
            isRequired
            placeholder={isViewOnly ? 'bills.pay.confirm.completeLegalInfo.legalName.emptyLabel' : ''}
            errorMessage={
              !legalCompanyName && !isViewOnly
                ? 'bills.pay.confirm.completeLegalInfo.legalName.emptyLabel'
                : validationErrors.legalCompanyName
            }
            helperText={isViewOnly ? '' : 'bills.pay.confirm.completeLegalInfo.legalName.inputHint'}
            isViewOnly={isViewOnly}
            autoFocus={isAutofocus}
            size={isViewOnly ? 'sm' : 'md'}
          />
          <MIAddressAutocomplete
            id="legalAddress"
            addressLabel="bills.pay.confirm.completeLegalInfo.legalAddress.inputTitle"
            aptNumberLabel="form.addressAutocomplete.aptNumberLabel"
            aptNumberPlaceholder={isViewOnly ? 'form.addressAutocomplete.emptyNumberLabel' : ''}
            emptyAddressLabel="bills.pay.confirm.completeLegalInfo.legalAddress.emptyAddressLabel"
            addressPlaceholder={isViewOnly ? 'bills.pay.confirm.completeLegalInfo.legalAddress.emptyAddressLabel' : ''}
            emptyNumberLabel="form.addressAutocomplete.emptyNumberLabel"
            address={address}
            mode={formType === FormType.WIZARD ? AddressFormControlMode.INLINE : AddressFormControlMode.FORM}
            onChange={onAddressChange}
            errorMessage={validationErrors.legalGooglePlaceId || validationErrors.legalZipCode}
            isViewOnly={isViewOnly}
            required
            hideSuite
            autoComplete={FORM_AUTOCOMPLETE.OFF}
          />
          <RadioGroupField
            id="taxIdType"
            label={
              companyFormPage === CompanyFormPage.ONBOARDING
                ? 'bills.pay.confirm.completeLegalInfo.taxIdType.inputTitle'
                : 'onboarding.companyInfo.taxIdType.inputTitle'
            }
            value={taxIdType}
            onChange={(value: string) => onChange({ id: 'taxIdType', value })}
            isRequired
            errorMessage={
              !taxIdType && !isViewOnly
                ? 'bills.pay.confirm.completeLegalInfo.taxIdType.emptyLabel'
                : validationErrors.taxIdType
            }
            helperText={showTaxIdTypeNotice ? 'bills.pay.confirm.completeLegalInfo.taxIdType.inputHint' : undefined}
            isDisabled={isViewOnly}
            mb={isViewOnly ? 0 : undefined}
          >
            {getTaxIdTypesOptions().map((option) => (
              <Radio label={option.label} value={option.id} />
            ))}
          </RadioGroupField>
          <PasswordField
            id="taxId"
            label="bills.pay.confirm.completeLegalInfo.taxId.inputTitle"
            value={taxId}
            onChange={handleFieldChange}
            isRequired
            placeholder="bills.pay.confirm.completeLegalInfo.taxId.emptyLabel"
            errorMessage={
              !taxId && !isViewOnly ? 'bills.pay.confirm.completeLegalInfo.taxId.emptyLabel' : validationErrors.taxId
            }
            isViewOnly={isViewOnly}
            isReadOnly={isViewOnly}
            size={isViewOnly ? 'sm' : 'md'}
            autoComplete={FORM_AUTOCOMPLETE.OFF}
          />
        </>
      ) : null}
      {showDateOfBirth ? (
        <MaskField
          id="dateOfBirth"
          label="vendors.deliveryMethods.international.businessDetails.dateOfBirth"
          value={dateOfBirth}
          onChange={({ formattedValue }) => {
            onChange({ id: 'dateOfBirth', value: formattedValue });
          }}
          isRequired
          format={Consts.MASK_FORMAT_OPTIONS.dateOfBirth}
          errorMessage={validationErrors?.dateOfBirth}
          autoComplete={FORM_AUTOCOMPLETE.OFF}
          placeholder="vendors.deliveryMethods.international.businessDetails.dateOfBirthPlaceholder"
          isViewOnly={isViewOnly}
        />
      ) : null}
    </PrivateDataContainer>
  );
};

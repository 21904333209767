import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { InitOptions, SpaceApi } from '@usersnap/browser';
import { format } from 'date-fns';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import config from 'src/config';
import organizationStore from 'src/modules/organizations/organizations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { getCanContactSupport, getOrgId } from 'src/redux/user/selectors';
import { FeatureFlags } from 'src/utils/featureFlags';

export const FeedbackContext = React.createContext<{
  feedbackApi?: SpaceApi | null;
}>({});

export const FeedbackProvider = ({ children }: { children: React.ReactNode }) => {
  const [feedbackApi, setFeedbackApi] = useState<SpaceApi | null>(null);
  const [isFeedbackWidgetOn] = useFeature(FeatureFlags.FeedbackWidget, false);
  const orgId = useSelector(getOrgId);
  const user = useSelector(profileStore.selectors.profile);
  const organization = useSelector(organizationStore.selectors.byId(orgId));
  const canContactSupport = useSelector(getCanContactSupport);

  const getInitData = useCallback(() => {
    let initUser: InitOptions['user'];
    let initCustom: InitOptions['custom'];

    if (user && organization) {
      initUser = {
        userId: user?.id,
        email: user.email,
      };
      initCustom = {
        organizationId: organization?.id,
        userCreatedAt: user?.createdAt ? format(new Date(user?.createdAt), 'MM/dd/yyyy') : undefined,
      };
    }

    return {
      user: initUser,
      custom: initCustom,
    };
  }, [organization, user]);

  useEffect(() => {
    let usersnapApi: SpaceApi | null = null;
    const script = document.createElement('script');

    if (isFeedbackWidgetOn && canContactSupport) {
      const { user, custom } = getInitData();

      window.onUserSnapLoad = async function (api: SpaceApi) {
        await api.init({ user, custom });
        usersnapApi = api;
        setFeedbackApi(api);
      };
      script.defer = true;
      script.src = `https://widget.usersnap.com/global/load/${config.services.feedbackProvider.userSnapSpaceId}?onload=onUserSnapLoad`;
      document.getElementsByTagName('head')[0].appendChild(script);
    }

    return () => {
      if (usersnapApi) {
        usersnapApi.destroy();
      }

      script.remove();
    };
  }, [getInitData, isFeedbackWidgetOn, canContactSupport]);

  return <FeedbackContext.Provider value={{ feedbackApi }}>{children}</FeedbackContext.Provider>;
};

export function useFeedbackApi() {
  return useContext(FeedbackContext);
}

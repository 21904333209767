import { useFeature } from '@melio/shared-web/dist/feature-flags';
import config from 'src/config';
import { FeatureFlags } from 'src/utils/featureFlags';
import { getVendorHandleURL } from 'src/utils/organizations';

export const useFullPaymentRequestLink = ({
  ownedVendorHandle,
  paymentRequestLink,
}: {
  ownedVendorHandle: string;
  paymentRequestLink: string;
}): string => {
  const [isNewPaymentRequestFulfillmentFlowEnabled] = useFeature(
    FeatureFlags.IsNewPaymentRequestFulfillmentFlow,
    false
  );

  if (isNewPaymentRequestFulfillmentFlowEnabled) {
    return `${config.platformApp.baseUrl}/ar/meliome/pay/r/${paymentRequestLink}`;
  }

  return `${getVendorHandleURL(ownedVendorHandle)}/r/${paymentRequestLink}`;
};

import { CustomFeed } from 'src/components/braze/CustomFeed';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';

const sxForCard: CSSObject = {
  mt: 4,
};

type Props = {
  doneLabel: string;
  buttonAction: () => void;
  doneLinkLabel?: string;
  linkAction?: () => void;
};

export const CompanyAddedSuccessFooter = ({ doneLabel, buttonAction, doneLinkLabel, linkAction }: Props) => (
  <Flex direction="column" w="full">
    <Box mb={5}>
      <Button
        label={doneLabel}
        variant={ButtonVariants.primary}
        size={ButtonSizes.lg}
        onClick={buttonAction}
        isFullWidth
      />
    </Box>
    {doneLinkLabel ? (
      <Button label={doneLinkLabel} variant={ButtonVariants.tertiaryNaked} size={ButtonSizes.lg} onClick={linkAction} />
    ) : null}
    <CustomFeed feedType="companysetup_below" sxForCard={sxForCard} />
  </Flex>
);

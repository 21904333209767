import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import { SelectItemCard } from 'src/components/common/SelectItemCard';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { ButtonSpacer, WizardNotificationCard } from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { NotificationCardTypes } from 'src/utils/consts';
import { useReceivingMethodConfig } from '../context/ReceivingMethodContext';

type Props = {
  onPrev?: () => void;
  onExit?: () => void;
  onNextPlaid: (deliveryMethodId?: string) => void;
  onNextManual: (deliveryMethodId?: string) => void;
  progressBarProps: Record<string, any>;
};

const ReceivingMethodSelectVerificationTypePage = ({
  onPrev,
  onExit,
  onNextPlaid,
  onNextManual,
  progressBarProps,
}: Props) => {
  const { title, subtitle, subTitleValues, plaid, manual } = {
    ...useReceivingMethodConfig({
      page: 'ReceivingMethodSelectVerificationTypePage',
    }),
  };
  const { state: locationState } = useLocation<{ preservedState: Record<string, string> }>();
  const params = useParams<{ deliveryMethodId?: string }>();
  const deliveryMethodId = params?.deliveryMethodId || '';
  const deliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(deliveryMethodId));
  const bankAccount4LastDigits = getAccountNumber4digits(deliveryMethod?.bankAccount);
  const shouldShowPlaidErrorNotification = locationState?.preservedState?.shouldShowPlaidErrorNotification;

  return (
    <StepLayoutPage
      title={title}
      subtitle={subtitle}
      goExit={onExit}
      onPrev={onPrev}
      subTitleValues={subTitleValues}
      innerSize={50}
      footer={<MISecurityDetails eventPage="onboarding-vendor-delivery-method" />}
      hideHeader
      {...progressBarProps}
    >
      <Container>
        {shouldShowPlaidErrorNotification && (
          <Box mb={[5, 5, '-2rem']} maxW="45rem">
            <WizardNotificationCard
              type={NotificationCardTypes.ERROR}
              title={{
                label: 'onboarding.fundingSources.bank.verification.msnClaimAccount.plaidErrorNotification.title',
                values: { bankAccount4LastDigits },
              }}
              subtitle={{
                label: 'onboarding.fundingSources.bank.verification.msnClaimAccount.plaidErrorNotification.subtitle',
                values: { bankAccount4LastDigits },
              }}
            />
          </Box>
        )}
        <SelectItemCard
          id="plaid"
          testId="verification-type-plaid"
          onClick={() => onNextPlaid(deliveryMethodId)}
          label={plaid?.label || 'onboarding.deliveryMethods.bank.verification.plaid.label'}
          description={plaid?.description || 'onboarding.deliveryMethods.bank.verification.plaid.description'}
          recommended
        />
        <ButtonSpacer />
        <SelectItemCard
          id="manual"
          testId="verification-type-manual"
          onClick={() => onNextManual(deliveryMethodId)}
          label={manual?.label || 'onboarding.deliveryMethods.bank.verification.manual.label'}
          description={manual?.description || 'onboarding.deliveryMethods.bank.verification.manual.description'}
        />
      </Container>
    </StepLayoutPage>
  );
};

export default ReceivingMethodSelectVerificationTypePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

import isEmpty from 'lodash/isEmpty';
import { ActionsDropdown } from 'src/components/common/ActionsDropDown';
import { MICardField, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Checkbox } from 'src/core/ds/checkbox';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { useModal } from 'src/helpers/react/useModal';
import { useToggle } from 'src/helpers/react/useToggle';
import { InvoiceType } from 'src/modules/invoices/types';
import { useFullPaymentRequestLink } from 'src/pages/get-paid/hooks/useFullPaymentRequestLink';
import RequestLink from 'src/pages/get-paid/list/components/RequestLink';
import ResendRequestButton from 'src/pages/get-paid/list/components/ResendRequestButton';
import { useGetAccountingSoftwareName } from 'src/pages/get-pro/hooks/useGetAccountingSoftwareName';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { ModelViewField } from 'src/ui/form/use-form/useForm';
import { getAccountingSoftwareDisplayName } from 'src/utils/accounting-software';
import { InvoiceStatus } from 'src/utils/consts';

type Props = {
  invoice: InvoiceType;
  vendorHandle: string;
  email: ModelViewField<string>;
  note: ModelViewField<string>;
  viewOnly: boolean;
  showMenu: boolean;
  onEdit: () => void;
  remove: (removeInvoice?: boolean) => Promise<void>;
  inEditMode: boolean;
  onMarkInvoiceAsPaid?: () => void;
  showGetPaidTabs?: boolean;
};

const RequestDetails = ({
  invoice,
  vendorHandle,
  email,
  note,
  showMenu,
  viewOnly,
  onEdit,
  remove,
  inEditMode,
  onMarkInvoiceAsPaid,
  showGetPaidTabs,
}: Props) => {
  const [removeInvoice, removeInvoiceToggle] = useToggle(false);
  const removeInvoiceCheckbox = (
    <Checkbox onChange={(event) => removeInvoiceToggle(event.target.checked)} isChecked={removeInvoice}>
      <MIFormattedText label="getPaid.view.paymentRequest.deleteMessage.removeInvoice" />
    </Checkbox>
  );
  const [DeleteInvoiceModal, onDelete] = useModal(CommonDialog, {
    confirm: () => remove(removeInvoice),
    title: 'getPaid.view.paymentRequest.deleteMessage.title',
    textValues: {
      totalAmount: invoice?.totalAmount,
      companyName: invoice?.customer?.contactName,
      removeInvoice: removeInvoiceCheckbox,
    },
    description: 'getPaid.view.paymentRequest.deleteMessage.description',
    confirmText: 'getPaid.view.paymentRequest.deleteMessage.confirm',
    cancelText: 'getPaid.view.paymentRequest.deleteMessage.cancel',
  });
  const fullPaymentRequestLink = useFullPaymentRequestLink({
    ownedVendorHandle: vendorHandle,
    paymentRequestLink: invoice.link || '',
  });
  const accountingSoftwareName = useGetAccountingSoftwareName(invoice.createOrigin);
  const integrationName = accountingSoftwareName && getAccountingSoftwareDisplayName(accountingSoftwareName);
  const integrationNameDisplayShortName = integrationName?.displayShortName;
  const resendButton = !isEmpty(invoice?.customer?.contactEmail) && invoice.status === InvoiceStatus.PENDING && (
    <ResendRequestButton invoice={invoice} />
  );
  const actionOptions = [
    ...(onMarkInvoiceAsPaid
      ? [
          {
            label: 'getPaid.view.paymentRequest.markAsPaid',
            action: onMarkInvoiceAsPaid,
            disabled: accountingSoftwareName
              ? {
                  label: 'getPro.table.item.menu.disabledMenuItemTooltip.markAsPaid',
                  values: {
                    integrationName: integrationNameDisplayShortName,
                  },
                }
              : false,
          },
        ]
      : []),
    {
      label: 'getPaid.view.paymentRequest.edit',
      action: onEdit,
      disabled: accountingSoftwareName
        ? {
            label: 'getPro.table.item.menu.disabledMenuItemTooltip.edit',
            values: {
              integrationName: integrationNameDisplayShortName,
            },
          }
        : false,
    },
    {
      label: 'getPaid.view.paymentRequest.delete',
      action: onDelete,
      negative: true,
    },
  ];

  return (
    <MICardForm noHorizontalPadding={showGetPaidTabs}>
      {DeleteInvoiceModal}
      <Flex justify="space-between">
        <MICardTitle label="getPaid.view.paymentRequest.title" />
        {showMenu && <ActionsDropdown testId="getPaid.view.request.actionsMenu" actionOptions={actionOptions} />}
      </Flex>
      <Flex direction="column">
        <MICardField label="getPaid.view.paymentRequest.link">
          <RequestLink
            link={fullPaymentRequestLink}
            lastSeen={invoice.lastSeen}
            resendButton={resendButton}
            inEditMode={inEditMode}
          />
        </MICardField>
        <MICardField label="getPaid.view.paymentRequest.email">
          <PrivateDataContainer>
            <TextField
              model={email}
              isViewOnly={viewOnly}
              type="email"
              placeholder="getPaid.view.paymentRequest.emailEmpty"
              size="sm"
              mb={0}
              isRequired
            />
          </PrivateDataContainer>
        </MICardField>
        <MICardField label="getPaid.view.paymentRequest.note">
          <PrivateDataContainer>
            <TextField
              model={note}
              placeholder="getPaid.view.paymentRequest.noteEmpty"
              isViewOnly={viewOnly}
              size="sm"
              mb={0}
              isRequired
            />
          </PrivateDataContainer>
        </MICardField>
      </Flex>
    </MICardForm>
  );
};

export default RequestDetails;

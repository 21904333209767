import { fetchRequest, postRequest, putRequest } from 'src/services/api/api';
import { retry } from 'src/utils/retry';
import { AmexIndustryType, CompanyInfoType, UBORequestType } from 'src/utils/types';

export const organizationsApi = {
  getCompanyInfo(orgId: number, local = false) {
    const url = `/orgs/${orgId}/company-info?local=${local}`;

    return fetchRequest(url);
  },
  getQBCompanyInfo(orgId: number) {
    const url = `/orgs/${orgId}/quickbooks/get-company-info`;

    return fetchRequest(url, {});
  },
  getOrgByBillId(billId) {
    const url = `/orgs/orgByBillId/${billId}`;

    return fetchRequest(url);
  },

  updateCompanyInfo(
    orgId: number,
    companyInfo: Partial<CompanyInfoType> & {
      confirmCompanyInfo?: boolean;
    }
  ): Promise<{ companyInfo: CompanyInfoType }> {
    const url = `/orgs/${orgId}/company-info`;

    return putRequest(url, companyInfo);
  },

  getAccountsForBill(orgId: number) {
    const url = `/orgs/${orgId}/accounts-bill-options`;

    return fetchRequest(url);
  },

  getAccountsPaymentOptions(orgId: number) {
    const url = `/orgs/${orgId}/accounts-payment-options`;

    return fetchRequest(url);
  },

  runPaymentSync(orgId: number, paymentId: number) {
    const url = `/orgs/${orgId}/quickbooks/sync/local-payment/${paymentId}`;

    return retry(() => fetchRequest(url), 500, 3);
  },

  runFullsync(orgId: number) {
    const url = `/orgs/${orgId}/full-sync`;

    return retry(() => fetchRequest(url), 500, 3);
  },

  setTraitsToDB(orgId, trait) {
    const url = `/orgs/${orgId}/traits`;

    return postRequest(url, trait);
  },

  switchAccountingFirm({ orgId }: { orgId: number }) {
    const url = '/orgs/switch-accounting-firm';

    return postRequest(url, { orgId });
  },

  requestSwitchAccountingFirm({ orgId }: { orgId: number }) {
    const url = '/orgs/request-switch-accounting-firm';

    return postRequest(url, { orgId });
  },

  createUltimateBeneficialOwner(orgId: number, params: readonly UBORequestType[]) {
    const url = `/orgs/${orgId}/organization-ultimate-beneficial-owner`;

    return postRequest(url, params);
  },

  getUltimateBeneficialOwner({ orgId }: { orgId: number }) {
    const url = `/orgs/${orgId}/organization-ultimate-beneficial-owner`;

    return fetchRequest(url);
  },

  getMccList(orgId, params): Promise<{ list: AmexIndustryType[] }> {
    const url = `/orgs/${orgId}/allowed-mcc`;

    return fetchRequest(url, params);
  },

  shouldDisplayDebitFeeNotification(orgId: number): Promise<{ result: boolean }> {
    const url = `/orgs/${orgId}/should-display-debit-fee-notification`;

    return fetchRequest(url);
  },

  getFreeChecks(orgId: number): Promise<{ total: number; available: number }> {
    const url = `/orgs/${orgId}/free-checks`;

    return fetchRequest(url);
  },

  complianceLimitations(
    orgId: number
  ): Promise<{
    isCompliance: boolean;
    isGracePeriodStarted: boolean;
    isGracePeriodEnded: boolean;
  }> {
    const url = `/orgs/${orgId}/compliance-limitations`;

    return fetchRequest(url);
  },
};

export const OrganizationRestApi = {
  fetch({ id }) {
    const url = `/orgs/${id}/company-info`;

    return fetchRequest(url);
  },
  update(companyInfo) {
    const url = `/orgs/${companyInfo.id}/company-info`;

    return putRequest(url, companyInfo).then((res) => ({
      id: companyInfo.id,
      ...res.companyInfo,
    }));
  },

  create(companyInfo) {
    const url = '/orgs/create-owned-organization';

    return postRequest(url, companyInfo);
  },
};
